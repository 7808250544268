#category{
  #products{
    #filter_onecolumn{
      #search_filters_wrapper{

        .title_block{
          display: none;
        }
        #search_filters{
          @include flexbox();
          @include justify-content(flex-start);
          @include align-items(flex-start);
          @include flex-wrap(wrap);
          .facet{
            width: 20%;
            max-width: 20%;
            @include rtl-padding-lr(10px, 10px);
            .facet-title{
              margin-bottom: 0!important;
            }
            .collapse {
              display: none;
              padding: 30px 15px;
              @include box-shadow(0 0 7px 0 rgba(0,0,0,0.1));
              li{
                .facet-label{
                  margin-bottom: 14px;
                  .custom-checkbox{
                    .ps-shown-by-js{
                      @include rtl-margin-lr(0, 19px);
                    }
                  }
                  .search-link{
                    font-size: 1.4rem;
                    color: #505050;
                    font-weight: 400;
                    @include transition(all 0.35s ease);
                    &:hover{
                      color: $theme-color-default;
                    }
                  }
                }
              }
              &.in{
                display: block;
                &.facet-color{
                  @include flexbox();
                  .facet-label{
                    .search-link{
                      font-size: 0!important;
                    }
                  }
                }
              }
            }
          }
          @media (max-width: $screen-sm-max) and (min-width: $screen-sm){
            .facet{
              width: 33.3333%;
              max-width: 33.33333%;
            }
          }
        }
      }
      @media (max-width: $screen-xs-max){
        display: block!important;
        #search_filters_wrapper{
          display: block!important;
          #active-search-filters, #_desktop_search_filters_clear_all{
            display: block!important;
          }
          #search_filter_controls{
            display: none;
          }

          #search_filters{
            .facet{
            width: 100%;
            max-width: 100%;
            @include rtl-padding-lr(10px, 10px);
            .facet-title{
              margin-bottom: 0!important;
            }
            .facet {
              &:first-child {
                padding-top: 0;
              }
              .faceted-slider{
                padding: 0;
                margin: 0;
                list-style: none;
                .ui-slider{
                  background: #e1e1e1;
                  border: none;
                  height: 8px;
                  @include border-radius(8px);
                  .ui-slider-range{
                    height: 8px;
                    background: #e1e1e1;
                    @include border-radius(8px);
                  }
                  .ui-widget-header{
                    background: $theme-color-default;
                  }
                  .ui-slider-handle{
                    background: $white;
                    @include size(19px, 19px);
                    border: 6px solid $theme-color-default;
                    @include border-radius(50%);
                    display: inline-block;
                    top: 50%;
                    @include translateY(-50%);
                    &:last-child{
                      @include rtl-margin-left(-19px);
                    }
                    &.ui-state-focus{
                      border-color: #2d2d2d;
                    }
                  }
                }
                span{
                  padding-top: 20px;
                  font-size: 12px;
                  color: #666666;
                  display: inline-block;

                }
              }
              .collapse {
                display: block;
                list-style: none;
                @include rtl-padding-left(0);
              }

              &.facet-hasscroll {
                .collapse {
                  overflow-y: scroll;
                  max-height: 160px;
                  &::-webkit-scrollbar-track{
                    background-color: #eaeaea;
                    @include border-radius(5px);
                  }
                  &::-webkit-scrollbar {
                    width: 8px;
                    background-color: #eaeaea;
                    @include border-radius(5px);
                  }
                  &::-webkit-scrollbar-thumb {
                    @include border-radius(5px);
                    background-color: $theme-color-primary;
                  }
                }
              }
              padding-top: 10px;
              .facet-title {
                font-family: $font-family-primary;
                color: #444;
                font-size: 12px;
                text-transform: uppercase;
                margin-bottom: 20px;
                padding-bottom: 8px;
                border-bottom: 1px dashed $base-border-color;
              }
              .facet-label {
                margin-bottom: 10px;
                @include flexbox();
                @include align-items(center);
                .custom-checkbox, .custom-radio{
                  display: inline-block;
                  top: 0;
                }
                a {
                  color: $link-color;
                  display: inline-block;
                  font-size: 1.2rem;
                  vertical-align: middle;
                  line-height: 1;
                  margin-top: 4px;
                }
              }
            }
            .collapse {
              display: none;
              padding: 30px 15px;
              @include box-shadow(0 0 7px 0 rgba(0,0,0,0.1));
              li{
                .facet-label{
                  margin-bottom: 14px;
                  .custom-checkbox{
                    .ps-shown-by-js{
                      @include rtl-margin-lr(0, 19px);
                    }
                  }
                  .search-link{
                    font-size: 1.4rem;
                    color: #505050;
                    font-weight: 400;
                    @include transition(all 0.35s ease);
                    &:hover{
                      color: $theme-color-default;
                    }
                  }
                }
              }
              &.in{
                display: block;
                &.facet-color{
                  @include flexbox();
                  .facet-label{
                    .search-link{
                      font-size: 0!important;
                    }
                  }
                }
              }
            }
          }
          }
        }      
      }
    }
  }
}

#categories-product {
  .item {
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

#js-product-list {
  .product_list {
    &.grid{
      .product-miniature{
        margin-top: 0;
        .product-groups{
          .group-reviews, .product-desc{
            display: none;
          }
        }
      }
    }
    &.row {
      margin-right: -15px;
      margin-left: -15px;
      > [class^="col-"], > [class*=" col-"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &.list{
      @include rtl-margin-lr(0,0);
      .item{
        @include rtl-padding-lr(0,0);
      }
      .product-miniature{
        @include rtl-margin-lr(0,0);
        .thumbnail-container{
          @include rtl-padding-lr(0,0);
        }
        .product-groups{
          padding: 0 30px;
        }
        .info-stock{
          display: block;
          color: $theme-color-default;
          .control-label{
            display: none;
          }
          i{
            color: $theme-color-default
          }
        }
      }
    }
  }
}

.block-category {
  margin-bottom: 60px;
  display: none;
  h1 {
    font-weight: 600;
    line-height: 1;
    margin-bottom: 50px;
    font-size: 2rem;
    color: $gray-dark;
    text-transform: capitalize;
  }
  #category-description {
    background-color: #f3f3f3;
    @include border-radius(5px);
    padding: 30px;
    p {
      font-size: 1.3rem;
      color: $text-color-custom;
      margin-bottom: 0;
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
  .category-cover {
    display: none;
  }
}

//nav-top
#nav-top {
  position: relative;
  margin-bottom: 57px;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #e8e7e7;
    top: 20px;
    left: 0;
    z-index: 0;
  }
}
  .change-type {
    display: inline-block;
    background: #fff;
    @include rtl-float-left();
    span {
      display: inline-block;
      @include square(35px);
      background: $theme-color-primary;
      text-align: center;
      line-height: 35px;
      @include border-radius(3px);
      color: #fff;
      @include rtl-margin-right(2px);
      cursor: pointer;
      i {
        font-size: 14px;
        @include square(35px);
        line-height: 35px;
      }
      &.list-type {
        @include rtl-margin-right(0);
      }
      &.active, &:hover {
        background: $theme-color-default;
      }
    }
  }
  .total-products {
    display: inline-block;
    color: #969696;
    background: #fff;
    @include rtl-float-left();
    font-size: 1.2rem;
    text-transform: capitalize;
    vertical-align: bottom;
    @include rtl-padding(10px,11px,10px,14px);
    p {
      margin-bottom: 0;
      font-size: 1.3rem;
      font-weight: 500;
      color: #6e6e6e;
    }
  }

.products-selection {
  .sort-by-row {
    @include display(flex);
    align-items: center;
  }
  .sort-by {
    white-space: normal;
    word-break: break-word;
    text-align: right;
    display: none;
  }
  h1 {
    padding-top: rem(10px);
  }
}

.products-sort-order {
  font-size: 1.2rem;
  color: #969696;
  text-transform: uppercase;
  @include rtl-padding-left(10px);
  background: #fff;
  .select-title {
    display: inline-block;
    width: 100%;
    color: #969696 !important;
    background: white;
    padding: 8px 10px;
    @include rtl-margin-left(0);
    border: 1px solid #f2f2f2;
    @include border-radius(3px);
    line-height: 1;
    cursor: pointer;
    text-transform: capitalize;
    span {
      display: inline-block;
      vertical-align: middle;
      text-transform: capitalize;
      font-size: 1.2rem;
      color: #6e6e6e;
    }
    i {
      @include rtl-margin(-3px, -7px, 0, 15px);
      vertical-align: middle;
      font-size: 20px;
      color: #969696;
    }
  }
  .select-list {
    display: block;
    color: $link-color;
    padding: 10px 15px;
    &:hover {
      color: $theme-color-primary;
      text-decoration: none;
    }
  }
  .dropdown-menu {
    left: auto;
    right: 0;
    width: 170px;
    background: #fff;
    border: 1px solid $base-border-color;
    border-radius: 0;
    padding: 0;
    a{
      &:hover{
        color: $theme-color-default;
      }
    }
  }
}

.dropdown.open {
  .dropdown-menu {
    display: block;
  }
}
#search_filters_wrapper{
  padding-bottom: 5px;
  .title_block{
    font-size: 2rem;
    font-weight: 500;
    color: $theme-color-primary;
    margin-bottom: 23px;
    padding-bottom: 16px;
    border-bottom: 4px solid $theme-color-default;
    @include rtl-text-align-left();
    &:after{
      display: none;
    }
  }
  #_desktop_search_filters_clear_all{
    display: inline-block;
    .js-search-filters-clear-all{
      font-size: 1.1rem;
      font-weight: 500;
      background: $theme-color-default;
      padding: 11px 10px;
      text-transform: capitalize;
      color: $white;
      border: none;
      @include border-radius(5px);
      &:hover{
        background: $theme-color-primary;
      }
    }
  }
}
#search_filters {
  .searchfilter-title {
    font-family: $font-family-primary;
    color: #444;
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 8px;
  }
  #_desktop_search_filters_clear_all {
    i {
      vertical-align: middle;
      font-size: 12px;
      @include rtl-float-left();
      line-height: 1;
    }
    span {
      @include rtl-float-left();
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .facet {
    padding-top: 3px!important;
    margin-bottom: 38px;
    &:first-child {
      padding-top: 0;
    }
    .collapse {
      list-style: none;
      @include rtl-padding-left(0);
    }
    .title{
      position: relative;
      &[data-toggle="collapse"]{
        .collapse-icons{
          .remove{
            display: none;
          }
        }
        &[aria-expanded="false"]{
          .collapse-icons{
            .add{
              display: none;
            }
            .remove{
              display: block;
            }
          }
        }
      }
      .navbar-toggler{
        position: absolute;
        top: 4px;
        @include rtl-right(-3px);
        padding: 0;
        i{
          color: $theme-color-primary;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
    >ul{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .facet-color{
      display: block;
      @include rtl-padding-left(2px); 
      max-height: initial!important;
      overflow-y: initial!important;
      &.collapse{
        display: none;
      }
      &.in{
        display: inline-block;
      }
      >li{
        display: inline-block;
        float: left;
      }
      .facet-label{
        padding-top: 0;
        margin-bottom: 0;
        span{
          input{
            @include square(20px);
            margin: 0;
          }
          .color{
            @include square(20px);
            @include border-radius(2px);
            @include rtl-margin-right(10px);
          }
        }
        >a{
          font-size: 0;
        }
        &:hover, &.active{
          span{
            input{
              @include square(20px);
              margin: 0;
            }
          }
        }
      }
    }
    &.facet-hasscroll {
      .collapse {
        overflow-y: scroll;
        max-height: 138px;
        &::-webkit-scrollbar-track{
          background-color: #eaeaea;
          @include border-radius(5px);
        }
        &::-webkit-scrollbar {
          width: 8px;
          background-color: #eaeaea;
          @include border-radius(5px);
        }
        &::-webkit-scrollbar-thumb {
            @include border-radius(5px);
            background-color: $theme-color-default;
        }
      }
    }
    padding-top: 23px;
    .facet-title {
      font-family: $font-family-primary;
      color: $theme-color-primary;
      font-size: 1.4rem;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 26px;
      padding-bottom: 14px;
      border-bottom: 1px solid #f2f2f2;
      width: 100%;
    }
    .facet-label {
      margin-bottom: 11px;
      @include flexbox();
      @include align-items(center);
      a {
        color: #909090;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 400;
        vertical-align: middle;
        line-height: 1;
      }
      .custom-checkbox{
        input[type=checkbox] {
          @include opacity(0);
          cursor: pointer;
          position: absolute;
        }
      }
      .custom-radio{
        @include flexbox();
        @include rtl-margin-lr(0, 10px);
      }
      &.active{
        .custom-checkbox{
          span{
            background: $theme-color-default;
            border-color: $theme-color-default;
          }
        }
      }
    }

  }
}

#js-product-list-bottom {
  font-size: 13px;
  font-weight: 400;
  color: $text-color-custom;
  text-transform: uppercase;
  margin-top: 38px;
  position: relative;
  &:before {
    content: '';
    background: #e7e7e6;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
  }
}

.pagination {
  > div:first-child{
    line-height: 35px;
  }
  > div:last-child{
    @include rtl-text-align-right();
  }
  .showing {
    background: #fff;
    @include rtl-padding-right(35px);
    color: #6e6e6e;
    text-transform: capitalize;
    font-size: 13px;
  }
  .page-list {
    background: #fff;
    margin-bottom: 0;
    @include rtl-padding-left(25px);
    @include rtl-text-align-right();
    display: inline-block;
    vertical-align: middle;
    li {
      width: 35px;
      height: 35px;
      display: inline-block;
      @include rtl-margin-right(5px);
      border: 1px solid #d7d7d7;
      background: $white;
      @include border-radius(2px);
      vertical-align: middle;
      font-size: 13px;
      @include rtl-float-left();
      text-align: center;
      line-height: 35px;
      @include transition(all 0.35s ease);
      a {
        color: #9b9999;
      }
      &.previous, &.next{
        width: auto;
        background: transparent;
        font-size: 12px;
        padding: 0 20px;
        @include border-radius(3px);
        a {
          color: #6e6e6e;
        }
        &:hover{
          border-color: $theme-color-default;
          background: $theme-color-default;
          a{ color: $white; }
        }
      }
      &:last-child {
        @include rtl-margin-right(0);
      }
      &.current, &:hover{
        border-color: $theme-color-default;
        background: $theme-color-default;
        a{ color: $white; }
      }
      
    }
  }
  a {
    color: black;
    font-weight: 400;
  }
  .previous {
    float: left;
  }
  .next {
    float: right;
  }
  .disabled {
    color: $gray;
  }
  .current a {
    color: $brand-primary;
    text-decoration: none;
  }
}

.active_filters {
  padding: 0px;
  padding-bottom: 25px;
  .active-filter-title{
    font-family: $font-family-default;
    color: #101010;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 26px;
    padding-bottom: 14px;
    border-bottom: 1px solid #f2f2f2;
    display: block;
  }
  ul{
    padding: 0;
    margin: 0;
    .filter-block{
      @include rtl-margin-lr(0, 5px);
      margin-bottom: 10px;
      font-size: 1.1rem;
      font-weight: 500;
      color: $white;
      background: $theme-color-default;
      @include transition(all 0.35s ease);
      @include border-radius(5px);
      &:hover{
        background: $theme-color-primary;
      }
      .js-search-link{
        i{
          font-size: 1.1rem;
          color: $white;
          margin-top: 2px;
          @include rtl-margin-left(10px);
        }
      }
    }
  }
  ul {
    display: inline;

  }
  .filter-block {
    color: $link-color;
    margin-right: 10px;
    margin-bottom: 10px;
    background: white;
    padding: rem(10px);
    display: inline-block;
    font-size: $font-size-xs;
    .close {
      color: $link-color;
      font-size: $font-size-lg;
      opacity: 1;
      margin-top: rem(3px);
      margin-left: rem(5px);
    }
  }
}

.block-categories {
  padding-bottom: 19px!important;
  .category-top-menu {
    @include rtl-padding-left(0);
    list-style: none;
    margin-bottom: 0;
    padding-bottom: 23px;
    @include border-radius(5px);
    > li {
      &:first-child {
        font-family: $font-family-default;
        font-size: 2rem;
        font-weight: 500;
        line-height: 0.8;
        margin-bottom: 23px;
        a {
          font-size: 2rem;
          font-weight: 500;
          color: $theme-color-primary;
          display: block;
          line-height: 0.8;
          padding-bottom: 16px;
          border-bottom: 4px solid $theme-color-default;
        }
      }
    }
  }
  .category-sub-menu {
    @include rtl-padding-left(0);
    list-style: none;
    .category-sub-menu {
      padding-top: 12px;
    }
    .category-sub-link{
      font-size: 13px;
      color: #909090;
      &:hover {
        color: $theme-color-default;
      }
    }
    li {
      position: relative;
      &[data-depth="1"] {
        @include rtl-padding-left(16px);
        margin-bottom: 4px;
        padding-bottom: 3px;
        &:last-child {
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
        > a {
          color: #505050;
          text-transform: initial;
          font-size: 14px;
          font-weight: 400;
          &:hover {
            color: $theme-color-default;
          }
        }
      }
    }
    li[data-depth="0"] {
      margin-bottom: 3px;
      padding-bottom: 9px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
      }
      > a {
        font-family: $font-family-default;
        color: $theme-color-primary;
        font-size: 14px;
        width: 100%;
        display: inline-block;
        font-weight: 600;
        text-transform: capitalize;
        &:hover {
          color: $theme-color-default;
        }
      }
    }
    li:not([data-depth="0"]):not([data-depth="1"]) {
      @include rtl-padding-left(5px);
      margin-bottom: 7px;
      padding-bottom: 7px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &::before {
        content: "";
        @include square(6px);
        background: #6c6c6c;
        @include border-radius(50%);
        position: absolute;
        @include rtl-left(-12px);
        top: 0.9rem;
      }
    }
  }
  .collapse-icons {
    position: absolute;
    @include rtl-right(0);
    top: 2px;
    padding: 0;
    cursor: pointer;
    border: none;
    width: 15px;
    height: 15px;
    text-align: center;
    overflow: hidden;
    &[aria-expanded="true"] {
      > i.add {
        display: none;
      }
      > i.remove {
        display: block;
      }
    }
    .add,
    .remove {
      font-size: 13px;
      color: $theme-color-primary;
    }
    .remove {
      display: none;
    }
  }
  .arrows {
    position: absolute;
    @include rtl-right(-1px);
    top: 0;
    padding: 0;
    cursor: pointer;
    &[aria-expanded="true"] {
      > i.add {
        display: none;
      }
      > i.remove {
        display: block;
      }
    }
    .add,
    .remove {
      padding: 2px;
      @include rtl-padding-right(1px);
      border-radius: 3px;
      font-size: 1.5rem;
      color: #898989;
      &:hover {
        color: $theme-color-default;
      }
    }
    .remove {
      display: none;
    }
  }
  .collapse {
    &[aria-expanded="true"] {
      display: block;
    }
  }
}

.facets-title {
  color: $link-color;
}
.products-selection {
  .filter-button {
    .btn-secondary {
      padding: rem(12px) rem(8px) rem(11px);
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #category {
    #left-column {
      width: 100%;
      #search_filters_wrapper {
        
      }
      #search_filter_controls {
        text-align: center;
        button {
          margin: 0 rem(8px);
        }
        margin-bottom: rem(16px);
      }
      #search_filters {
        .facet {
          
        }
      }
    }
    #content-wrapper {
      width: 100%;
    }
    #search_filter_toggler {
      width: 100%;
    }
  }
  
  .products-selection {
    h1 {
      padding-top: 0;
      text-align: center;
      margin-bottom: rem(16px);
    }
    .showing {
      padding-top: rem(16px);
    }
  }
  #prices-drop #content-wrapper,
  #new-products #content-wrapper,
  #best-sales #content-wrapper {
    width: 100%;
  }
}