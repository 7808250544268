// BREADCRUMB
.breadcrumb {
  background: $theme-color-default;
  color: $theme-color-primary;
  font-size: 1.4rem;
  margin-bottom: 12rem;
  @include border-radius(0);
  padding: 0;
  .title-breadcrumb{
    text-align: center;
    font-size: 3.2rem; 
    font-weight: 600;
    color: $white;
    text-transform: capitalize;
    padding-top: 39px;
    padding-bottom: 43px;
  }
  .breadcrumb-link{
    padding-top: 29px;
    padding-bottom: 29px;
    background: #f2f2f2;
    ol {
      @include rtl-text-align-left();
      font-family: $font-family-default;
      margin: 0;
      padding: 0;
      li {
        padding: 0 30px;
        position: relative;
        display: inline-block;
        color: $breadcrumb-link-color;
        font-size: 14px;
        a {
          font-size: 14px;
          font-weight: 400;
          color: #505050;
          @include flexbox();
          &:hover {
            color: $theme-color-default;
          }

        }
        &:before {
          content: "\e916";
          font-family: $font-icon-tello;
          font-size: 13px;
          color: #505050;
          position: absolute;
          top: 50%;
          @include translateY(-50%);
          @include rtl-left(-4px);
        }
        &:first-child {
          @include rtl-padding-lr(0px,30px);
          &:before {
            display: none;
          }
        }
        &:last-child{
          @include rtl-padding-lr(30px,0px);
          >a{
            >span{
              max-width: 200px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  
}