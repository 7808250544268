body:not(.page-index){
  #wrapper-site{
    @media (min-width: 1200px){
      .container{
        width: 1200px!important;
        max-width: 100%;
      }
    }
  }
  #header.header-3 .header-center .search-widget.active-search .form-search{
   .ui-autocomplete-input{
    background: #f5f5f5;
   }    
  }
  #header{

  }
  //Footer
  .footer{
    
  }
}
//Header style
#header {
  position: relative;
  background: $header-bg;
  color: $header-color;
  font-size: $header-font-size;
  @mixin header-search(){
    .header_search{
      .search-widget{
        .toggle-search{
          @include flexbox();
          @include rtl-margin-lr(0, 10px);
        }
        .form-search{
          position: absolute;
          @include transition(all 0.5s ease);
          top: 50%;
          @include translateY(-50%);
          @include rtl-right(0);
          @include opacity(0);
          visibility: hidden;
          .ui-autocomplete-input{
            @include border-radius(40px);
          }
        }
        &.active-search{
          .form-search{
            @include opacity(1);
            visibility: visible;
            @include rtl-right(170px);
          }
        }
      }
    }
  }
  .group-header-list{
    .header_link_myaccount{
      .block-novaccount{
        @include square(40px);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include border-radius(50%);
        @include transition(all 0.35s ease);
        @include rtl-margin-lr(0, 8px);  
        cursor: pointer;
        .account{
          @include flexbox();
          @include justify-content(center);
          @include align-items(center);
          @include square(40px);
        }
        .icon-novaccount{
          background-image: url(../img/icon/icon-group-header.png);
          background-repeat: no-repeat;
          background-position: -33px -0px;
          width: 21px;
          height: 21px;
          @include transition(all 0.35s ease);
          display: inline-block;
        }
        &:hover{
          background: $theme-color-default;
          .icon-novaccount{
            background-position: -33px -24px;
          }
        }
      }
    }
    .header_link_wishlist{
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include square(40px);
      min-width: 40px;
      @include border-radius(50%);
      @include rtl-margin-lr(0, 9px);
      @include transition(all 0.35s ease);
      a{
        display: inline-block;
        .icon-novwishlist{
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          @include square(40px);
          background-image: url(../img/icon/icon-group-header.png);
          background-repeat: no-repeat;
          background-position: -96px -0px;
          width: 23px;
          height: 21px;
          @include transition(all 0.35s ease);
        }
      }      
      &:hover{
        background: $theme-color-default;
        a{
          .icon-novwishlist{
            background-position: -96px -24px;
          }
        }
        
      }
    }
    .header_link_myaccount_infos{
      .myaccount-icon{
        width: 40px;
        height: 40px;
        @include rtl-margin-right(-17px);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include border-radius(50%);
        @include transition(all 0.35s ease);
        cursor: pointer;
        &:after{
          display: none;
        }
        .icon_link_myaccount_infos{
          display: inline-block;
          background-image: url(../img/icon/icon-group-header.png);
          background-repeat: no-repeat;
          background-position: -67px -0px;
          width: 21px;
          height: 21px;
          @include transition(all 0.35s ease);
        }
        &:hover{
          background: $theme-color-default;
          .icon_link_myaccount_infos{
            background-position: -66px -24px;
          }
        }
      }
      .account-list{
        top: calc(100% + 25px);
      }
    }
  }
  &.header-1{

    .header-top{
      .container{
        @media (min-width: 1200px){
          width: 1830px;
        }
      }
      .group-header-list{
        .header_search{
          @include rtl-margin-lr(0, 7px);
        }
      }
      .header-menu{
        .nov-megamenu{
          .menu{
            >.item{
              &:last-child{
                @include rtl-padding-lr(0, 23px);
              }
              &:last-child{
                @include rtl-padding-lr(23px, 0);
              }
            }
          }
        }
      }
    }
  }
  &.header-2{
    z-index: 44;
    background: #fff;
    .header-top{
      .container{
        @media (min-width: 1200px){
          width: 1830px;
        }
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 280px;
          height: 280px;
          @include border-radius(50%);
          background: #fff;
          bottom: calc(100% - 142px);
          @include rtl-left(50%);
          @include translateX(-50%);
        }
      }
      .group-header-list{
        .header_search{
          @include rtl-margin-lr(0, 7px);
        }
      }
      .header-menu{
        .nov-megamenu{
          .menu{
            >.item{
              &:last-child{
                @include rtl-padding-lr(0, 23px);
              }
              &:last-child{
                @include rtl-padding-lr(23px, 0);
              } 
            }
          }
        }
      }
    }
  }
  &.header-3{
    .container{
      @media (min-width: 1200px){
        width: 1830px;
      }
    }
    .header-top{
      .group-selector-header{
        padding-top: 10px;
        padding-bottom: 10px;
        .currency-selector{
          position: relative;
          &:after{
            content: "";
            position: absolute;
            width: 1px;
            height: 11px;
            top: 50%;
            @include translateY(-50%);
            @include rtl-right(0);
            background: #999999;
          }
          .dropdown-toggle{
            @include rtl-padding-lr(0, 11px);
            position: relative;
            .expand-more{
              font-size: 14px;
              color: #999999;
              font-weight: 500;
              background: transparent;
              text-transform: initial;
              padding: 0;
              margin: 0;
            }
            &:before{
              content: "\f0dd";
              display: inline-block;
              font-size: 14px;
              font-family: $font-icon-tello;
              position: absolute;
              @include rtl-right(0);
              top: -4px;
              color: #999999;
            }
          }
          .dropdown-menu{
            top: 100%;
          }
        }
        >div{
          @include rtl-padding-lr(25px, 25px);
          &:last-child{
            @include rtl-padding-lr(25px, 0px);
          }
          &:first-child{
            @include rtl-padding-lr(0, 25px);
          }
        }
        .language-selector{
          position: relative;
          .dropdown-menu{
            margin: 0;
            padding: 0;
            border: none;
            box-shadow: 0 0 5px rgba(0,0,0,0.2);
            @include rtl-left(25px);
            .language-list-content{
              .language-item{
                >div{
                  padding: 7px 50px 7px 10px;
                  border-bottom: 1px solid #eaeaea;
                  a{
                    span{
                      font-size: 1.3rem;
                    }
                  }
                }
                &:last-child{
                  >div{
                    border-bottom: none;
                  }
                }
              }
            }
          }
          .language-text{
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
          .dropdown-toggle{
            display: inline-block;
            @include rtl-padding-lr(0, 11px);
            position: relative;
            cursor: pointer;
            .expand-more{
              font-size: 14px;
              font-weight: 500;
              color: #999999;
              text-transform: uppercase;
            }
            &:after{
              display: none;
            }
            &:before{
              content: "\f0dd";
              display: inline-block;
              font-size: 14px;
              font-family: $font-icon-tello;
              position: absolute;
              @include rtl-right(0);
              top: -4px;
              color: #999999;
            }
          }
        }
      }
      .group-header-top-right{
        >div{
          @include rtl-padding-lr(25px, 25px);
          position: relative;
          &:after{
            content: "";
            position: absolute;
            top: 5px;
            @include rtl-right(0);
            width: 1px;
            height: 11px;
            background-color: #999999;
          }
          >a{
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
            &:hover{
              color: $theme-color-primary;
            }
          }
        }
        .nov-account{
          @include rtl-padding-lr(25px, 10px);
          &:after{
            @include skew(-13deg, 0deg);  
            height: 10px;
          }
        }
        .nov-register{
          @include rtl-padding-lr(7px,0);
          &:after{
            display: none;
          }
        }
      }
    }
    .header-center{
      background: transparent;
      position: relative;
      z-index: 2;
      .nov-megamenu{
        .menu{
          >.item{
            >.dropdown-menu{
              left: auto!important;
              right: -60px;
            }
            &:first-child{
              >.dropdown-menu{
                left: -20px important;
                right: auto;
              }
            }
          }
        }
      }
      .search-widget{
        position: relative;
        .toggle-search{
          @include flexbox();
          visibility: visible;
          &:hover{
            .search-header{
              background-position: -45px 0;
            }
          }
        }
        .form-search{
          visibility: hidden;
          @include opacity(0);
          position: absolute;
          @include rtl-right(-12px);
          top: 100%;
          @include translateY(-50%);
          @include transition(all 0.5s ease-in-out);
          height: 50px;
          form{
            position: relative;
          }
          .ui-autocomplete-input{
            background: $white;
          }
        }
        &.active-search{
          .toggle-search{
            @include flexbox();
            visibility: hidden;
          }
          .form-search{
            visibility: visible;
            @include opacity(1);
            top: 50%;
            bottom: 0;
          }
        }
      }
    }
  }
}

#displayTop {
  .nov-row {
    &.no-gutters .nov-row-wrap {
      margin-right: 0;
      margin-left: 0;
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  &.displaytoptwo{

  }
  &.displaytopthree{
    margin-top: -100px;
    #nov-slider{
      .nivo-controlNav{
        .nivo-control{
          background: $theme-color-primary;
          &.active, &:hover{
            background: $white;
          }
        }
      }
    }
  }
}

//Homepage style
.page-home {
  .practica-content{
    >div{
      margin-bottom: 60px;
      &:last-child{
        margin-bottom: 84px;
      }
    }
    .practical-left{
      @include rtl-margin-lr(0, 15px);
    }
    .practical-right{
      h3{
        font-size: 18px;
        font-weight: 600;
        color: $theme-color-primary;
        margin-bottom: 18px;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        color: $theme-color-primary;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }
  .featured-main{
    .image-featured{
      img{
        @include transition(all 0.7s ease-in-out 0.3s);
      }
      &:hover{
        img{
          @include scale(1.05, 1.05);
        }
      }
    }
    .content-featured{
      overflow: hidden;
      padding-bottom: 80px;
      .slick-list{
        overflow: initial;
        .slick-slide{
          >div{
            @include rtl-padding-right(5px);
          }
        }
      }
      .slick-arrow{
        display: none!important;
      }
      .slick-list{
        .slick-slide{
          outline: none;
          &:focus{
            outline: none;
          }
          .item-featured{
            background: $white;
            @include border-radius(4px);
            padding: 35px 15px 35px 20px;
            margin-bottom: 30px;
            .featured-left{
              @include flexbox();
              @include justify-content(center);
              @include align-items(center);
              @include border-radius(50%);
              @include square(80px);
              min-width: 80px;
              border: 1px solid $theme-color-default;
              @include rtl-margin-lr(0, 20px);
            }
            .featured-right{
              h3{
                font-size: 18px;
                font-weight: 600;
                color: $theme-color-primary;
                margin-bottom: 13px;
              }
              p{
                font-size: 14px;
                font-weight: 400;
                color: $theme-color-primary;
                line-height: 24px;
                margin-bottom: 0;
              }
            }
            &:hover{
              .featured-left{
                background-color: $theme-color-default;
                @include transition(all 0.35s ease);
                img{
                  @include filter(brightness(100));
                }
              }
              @include box-shadow(4px 4px 0px 0px rgba(159, 218, 18, 1)); 
            }
          }
        }
      }
      .slick-dots{
        bottom: 0;
        li{
          @include square(15px);
          @include border-radius(50%);
          background: $white;
          &.slick-active, &:hover{
            background: $theme-color-primary;
          }
        }
      }
    }
  }
  .youtube-main{
    .content-image{
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      &:hover{
        cursor: pointer;
      }
      .modal-youtube{
        .modal-dialog{
          max-width: 767px!important;
          position: relative;
          .close{
            position: absolute;
            font-family: $font-icon-tello;
            top: -20px;
            @include rtl-right(0);
            font-size: 20px;
            color: $white;
            outline: none;
            i{
              font-family: $font-icon-tello;
            }
            &:focus{
              outline: none;
            }
          }
        }
      }
    }
    .youtube-text{
      text-align: center;
      background: $theme-color-primary;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      .title_block{
        color: $white;
        margin-bottom: 64px;
      }
      h3{
        font-size: 18px;
        font-weight: 600;
        color: $white;
        margin-bottom: 18px;
      }
      p{
        font-size: 15px;
        font-weight: 400;
        color: #f2f2f2;
        line-height: 24px;
        max-width: 615px;
        margin: 0 auto;
      }
      .discover{
        display: inline-block;
        padding: 17px 40px;
        color: $white;
        border: 3px solid $white;
        @include border-radius(40px);
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 41px;
        @include transition(all 0.35s ease);
        &:hover{
          background: $theme-color-default;
          border-color: $theme-color-default;
        }
      }
    }
  }
  .our-featured-main{
    .content-our-featured{
      ul{
        list-style: none;
        padding: 0 0 0 30px;
        margin: 0;
        li{
        position: relative;
        margin-bottom: 57px;
        &:last-child{
          margin-bottom: 0;
        }
        &:before{
          content:"";
          position: absolute;
          top: 3px;
          @include rtl-left(-30px);
          @include square(10px);
          background: $theme-color-default;
          @include border-radius(50%);
        }
          h3{
            font-size: 18px;
            font-weight: 700;
            color: $theme-color-primary;
            margin-bottom: 13px;
          }
          p{
            font-size: 14px;
            font-weight: 400;
            color: $theme-color-primary;
            line-height: 24px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .about-us-main{
    text-align: center;
    p{
      max-width: 970px;
      font-size: 15px;
      color: $white;
      font-weight: 400;
      line-height: 26px;
      margin: 0 auto;
      padding-bottom: 62px;
    }
    .read-more{
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;
      padding: 17px 62px;
      border: 3px solid $white;
      @include border-radius(70px);
      @include transition(all 0.35s ease);
      &:hover{
        background: $theme-color-default;
      }
    }
  }
  .choose-us-main{
    .content-text-choose{
      .title-choose{
        font-size: 3.2rem;
        font-weight: 700;
        color: $theme-color-primary;
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 60px;
        line-height: 0.8;
        &:after{
          content:"";
          position: absolute;
          @include rtl-left(0);
          bottom: 0;
          width: 100px;
          height: 2px;
          background: $theme-color-default;
        }
      }
      p{
        font-size: 14px;
        font-weight: 400;
        color: $theme-color-primary;
        line-height: 24px;
        margin-bottom: 24px;
      }
      .discover{
        display: inline-block;
        font-size: 2rem;
        font-weight: 500;
        color: $white;
        text-transform: uppercase;
        padding: 20px 40px;
        background: $theme-color-default;
        @include border-radius(70px);
        @include transition(all 0.35s ease);
        margin-top: 40px;
        &:hover{
          background: $theme-color-primary;
        }
      }
    }
    .list-choose{
      ul{
        padding: 0;
        margin: 0;
        list-style: none;
        li{
          >div{
            background: $white;
            padding: 40px 20px 46px 20px;
            @include border-radius(7px);
            @include box-shadow(4px 4px 0 0 $theme-color-default);
            margin-bottom: 30px;
            text-align: center;
            img{
              @include transition(all 0.5s ease-in-out);
              display: inline-block;
              &:hover{
                @include rtl-rotate(360deg);
              }
            }
            h3{
              font-size: 18px;
              font-weight: 600;
              color: $theme-color-primary;
              line-height: 1.7;
              margin-top: 30px;
              display: inline-block;
            }
          }
        }
      }
      &.list-choose-last{
        margin-top: 70px;
      }
    }
  }
  .benefits-html{
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('../img/animation-left.jpg');
      background-repeat: 0;
      width: 745px;
      height: 500px;
    }
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url('../img/animation-right.jpg');
      background-repeat: 0;
      width: 738px;
      height: 500px;
    }
    .title_block{
      margin-bottom: 60px;
    }
    .block_content{
      position: relative;
      z-index: 2;
      .head-benefit{
        text-align: center;
        padding-bottom: 120px;
        >p{
          max-width: 680px;
          margin: 0 auto;
          font-size: 14px;
          font-weight: 400;
          color: $theme-color-primary;
          line-height: 24px;
        }
        img{
          display: inline-block;
          padding-top: 21px;
        }
      }
      .main-benefit{
        @include align-items(center);
        .content-benefit{
          @include flexbox();
          @include justify-content(start);
          @include align-content(center);
          ul{
            list-style: none;
            padding: 0 0 0 30px;
            margin: 0;
            li{
              position: relative;
              margin-bottom: 47px;
              &:last-child{
                margin-bottom: 0;
              }
              &:before{
                content:"";
                position: absolute;
                top: 3px;
                @include rtl-left(-30px);
                @include square(10px);
                background: $theme-color-default;
                @include border-radius(50%);
              }
              h2{
                font-size: 18px;
                font-weight: 700;
                color: $theme-color-primary;
                margin-bottom: 13px;
              }
              p{
                font-size: 14px;
                font-weight: 400;
                color: $theme-color-primary;
                line-height: 24px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .about-us-html{
    background: #f5f5f5;
    padding-top: 120px;
    .main-about{
      .content-about{
        .title-about{
          font-family: $font-family-primary;
          font-weight: 700;
          font-size: 3.2rem;
          color: $theme-color-primary;
          margin-bottom: 60px;
          line-height: 0.8;
          text-transform: capitalize;
          @include rtl-text-align-left();
          position: relative;
          padding-bottom: 15px;
          &:after{
            content: "";
            position: absolute;
            width: 100px;
            height: 2px;
            background: $theme-color-default;
            @include rtl-left(0);
            bottom: 0;
          }
        }
        p{
          font-size: 14px;
          font-weight: 400;
          color: $theme-color-primary;
          line-height: 24px;
          margin-bottom: 24px;
        }
        .button-about{
          display: inline-block;
          padding: 20px 60px;
          font-size: 2rem;
          font-weight: 500;
          color: $white;
          @include border-radius(70px);
          text-transform: uppercase;
          background: $theme-color-default;
          @include transition(all 0.35s ease);
          margin-top: 60px;
          &:hover{
            background: $theme-color-primary;
          }
        }
      }
    }
  }
  .choose-html{
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      @include rtl-left(0);
      background-image: url('../img/choose-bf.png');
      background-repeat: no-repeat;
      width: 441px;
      height: 314px;
    }
    &:after{
      content: "";
      position: absolute;
      bottom: 100px;
      @include rtl-right(0);
      background-image: url('../img/choose-at.png');
      background-repeat: no-repeat;
      width: 282px;
      height: 246px;
    }
    .title_block{
      margin-bottom: 60px;
    }
    .block_content{
      .head-choose-us{
        text-align: center;
        padding-bottom: 70px;
        >p{
          max-width: 700px;
          margin: 0 auto;
          font-size: 14px;
          color: $theme-color-primary;
          line-height: 24px;
          margin-bottom: 30px;
        }
      }
      .main-choose-us{
        .choose-us-item{
          border-right: 1px solid #f2f2f2;
          &:nth-child(1),&:nth-child(2), &:nth-child(3){
            border-bottom: 1px solid #f2f2f2;
          }
          &:nth-child(3n){
            border-right: none;
          }
          padding-top: 38px;
          padding-bottom: 23px;
          .img-content{
            min-width: 100px;
            text-align: center;
            @include rtl-padding-lr(0, 15px);
          }
          .text-content{
            h2{
              font-size: 1.8rem;
              font-weight: 600;
              color: $theme-color-primary;
              margin-bottom: 7px;
            }
            p{
              font-size: 14px;
              color: $theme-color-primary;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  &.pagehome-one {
    .practica{
      padding-top: 120px;
      position: relative;
      &:before{
        content:"";
        position: absolute;
        top: 80px;
        @include rtl-right(0);
        width: 314px;
        height: 210px;
        background-image: url(../img/Practical-BF.png);
        background-repeat: no-repeat;
      }
    }
    .featured-html{
      padding-top: 120px;
      padding-bottom: 88px;
      background: #f2f2f2;
    }
    .testimonial-home{
      padding-top: 120px;
      padding-bottom: 100px;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        top: 77px;
        @include rtl-right(0);
        width: 262px;
        height: 309px;
        background-image: url('../img/testimonial-bf.png');
        background-repeat: no-repeat;
      }
    }
    .instagram-home{
      padding-top: 120px;
    }
    .home-novgallery{
      padding-top: 114px;
      padding-bottom: 120px;
    }
    .policy-home{
      border-bottom: 1px solid #f2f2f2;
    }
  }
  &.pagehome-two{
    .policy-home{
      border-bottom: 1px solid #f2f2f2;
    }
    .our-featured{
      padding-top: 120px;
      padding-bottom: 120px;
    }
    .home-about-us{
      padding-top: 120px;
      padding-bottom: 120px;
      .title_block{
        color: $white;
        margin-bottom: 60px;
      }
    }
    .testimonial-home{
      padding-top: 120px;
      padding-bottom: 200px;
      .background-row{
        background-position: top right!important;
      }
      .nov-html{
        padding-top: 110px;
        .title_block{
          @include rtl-text-align-left();
          margin-bottom: 61px;
          &:after{
            @include rtl-left(0);
            @include translateX(0);
          }
        }
        .block_content{
          p{
            font-size: 14px;
            color: $theme-color-primary;
            line-height: 24px;
            @include rtl-padding-lr(0, 20px);
          }
        }
      }
    }
    .home-image360deg{
      padding-top: 120px;
      padding-bottom: 120px;
      .background-row{
        background-position: top center!important;
      }
    }
    .home-choose-us{
      padding-top: 120px;
      padding-bottom: 96px;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        bottom: -72px;
        @include rtl-left(0);
        width: 334px;
        height: 563px;
        background-image: url('../img/bg-choose-bf.png');
        background-repeat: no-repeat;
      }
    }
    .brand-home{
      .nov-manufacture{
        position: relative;
        &:after{
          content: "";
          position: absolute;
          bottom: 0;
          @include rtl-left(15px);
          width: calc(100% - 30px);
          height: 1px;
          background: #f2f2f2;
        }
      }          
    }
    .newsletter-home{
      padding-top: 120px;
      padding-bottom: 120px;
      .title_block{
        padding-bottom: 0;
        &:after{
          display: none;
        }
      }
      .block_newsletter{
        .input-group{
          .form-control{
            background: #f2f2f2;
          }
        }
      }
    }
    .nov-blog{
      padding-bottom: 116px;
    }
  }
  &.pagehome-three{
    .productlist-slider{
      padding-bottom: 120px;
    }
    .testimonial-home{
      padding-top: 170px;
      padding-bottom: 185px;
      .background-row{
        background-position: top center!important;
      }
    }
    .nov-blog{
      padding-bottom: 120px;
    }
    .nov-manufacture{
      .manufacture{
        .logo-manu{
          >a{
            @include border-radius(8px);
          }
        }
      }
    }
    .newsletter-home{
      padding-top: 120px;
      padding-bottom: 120px;
      .nov-modules{
        .block{
          .title_block{
            padding-bottom: 8px;
            .sub_title{
              margin-bottom: 29px;
            }
            &:after{
              display: none;
            }
          }
          .block_newsletter{
            .input-group{
              .form-control{
                background: #f2f2f2;
              }
            }
          }
        }
      }
    }
    .policy-home{
      border-top: 1px solid #f2f2f2;
    }
  }
}

//Footer Style
.footer {
  .links {
    a {
      color: #9f9d9d;
      font-size: 1.4rem;
      &:hover {
        color: $theme-color-default;
      }
    }
    .list-inline {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include flex-direction(row-reverse);
      @include flex-wrap(wrap);
      .list-inline-item {
        @include rtl-padding-right(17px);
        position: relative;
        a{
          font-size: 1.4rem;
          color: #9f9d9d;
          font-weight: 400;
          @include transition(all 0.35s ease);
          text-transform: capitalize;
          &:hover{
            color: $theme-color-default;
          }
        }
        &:last-child{
          @include rtl-margin-right(5px);
        }
        &:first-child {
          @include rtl-padding-right(0);
          &:before{
            display: none;
          }
        }
      }
    }
  }
  .address-footer{
    margin-top: -3px;
  }
  .data{
    .content-data{
      @include justify-content(center);
      @include align-items(center);
      margin-bottom: 13px;
      >span{
        font-size: 0;
        width: 25px;
        min-width: 25px;
        height: 25px;
        @include rtl-margin-lr(0, 10px);
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include border-radius(3px);
        background: $theme-color-default;
        i{
          font-size: 13px;
          color: $white;
        }
      }
      p{
        font-weight: 400;
        font-size: 14px;
        color: $theme-color-primary;
        margin: 0;
      }
    }
  }
  .payment-image-contact{
    padding-top: 18px;
  }
  

  &.footer-one {
    .inner-footer{
      background: #f2f2f2;
      .newsletter-home{
        .nov-modules{
          >.block{
            padding-top: 120px;
            padding-bottom: 120px;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            .title_block, {
              width: 58.333333%;;
            }
            .block_newsletter{
              width: 41.666667%;
            }
            .title_block{
              padding: 0;
              margin: 0;
              font-size: 3.2rem;
              color: $theme-color-primary;
              @include flexbox();
              @include flex-direction(column);
              .sub_title{
                font-size: 13px;
                font-weight: 400;
                color: $theme-color-primary;
                margin-top: 18px;
                margin-bottom: 0;
                text-transform: initial;
                line-height: initial;
              }
            }
          }
        }
      }
      .footer-center{
        padding-bottom: 80px;
      }
    }
    #nov-copyright{
      .image-payment{
        @include rtl-text-align-right();
      }
      .text-copyright{
        padding-top: 13px;
        padding-bottom: 14px;
        span{
          font-size: 14px;
          color: $theme-color-primary;
          font-weight: 400;
        }
      }
    }
  }
  &.footer-two{
    .inner-footer{
      background: #f2f2f2;
      .footer-center{
        padding-top: 120px;
        padding-bottom: 80px;
      }
    }
    #nov-copyright{
      .image-payment{
        @include rtl-text-align-right();
      }
      .text-copyright{
        padding-top: 13px;
        padding-bottom: 14px;
        span{
          font-size: 14px;
          color: $theme-color-primary;
          font-weight: 400;
        }
      }
    }
  }
  &.footer-three{
    .inner-footer{
      background: #f2f2f2;
      .footer-center{
        padding-top: 120px;
        padding-bottom: 80px;
        position: relative;
        &:after{
          content: "";
          position: absolute;
          @include rtl-left(15px);
          bottom: 0;
          width: calc(100% - 30px);
          height: 1px;
          background: $white;
        }
        .block_content{
          .content-text{
            line-height: 24px;
            margin-bottom: 33px;
            margin-top: -2px;
          }
        }
      }
    }
    #nov-copyright{
      background: #f2f2f2;
      padding-top: 39px;
      padding-bottom: 38px;

      .logo-footer{
        margin-bottom: 36px;
      }     
      .text-copyright{
        font-size: 14px;
        color: $theme-color-primary;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
}