//Style responsive
/*
xs: 0
$sm: 576px
$md: 768px
$lg: 992px
$xl: 1200px
$screen-xs-min: 575px;
$screen-xs-max : 767px;
$screen-sm-max : 991px;
$screen-md-max: 1199px;
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-lg-max: 1439px;
$screen-xlg: 1440px;
*/

@include media-breakpoint-up(xl) {
  .col-lg-cus-5 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 15px;
    padding-right: 15px;  
  }
  .col-lg-4-9 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 44.44444444%;
    -ms-flex: 0 0 44.44444444%;
    flex: 0 0 44.44444444%;
    max-width: 44.44444444%;
  }
  .col-lg-5-9 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 55.55555555%;
    -ms-flex: 0 0 55.55555555%;
    flex: 0 0 55.55555555%;
    max-width: 55.55555555%;
  }
  .col-lg-3-16 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -ms-flex: 0 0 19.75%;
    flex: 0 0 19.75%;
    max-width: 19.75%;
  }
  .col-lg-4-16 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -ms-flex: 0 0 25.85%;
    flex: 0 0 25.85%;
    max-width: 25.85%;
  }
  .col-lg-9-16 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -ms-flex: 0 0 54.4%;
    flex: 0 0 54.4%;
    max-width: 54.4%;
  }
  .col-lg-12-16 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -ms-flex: 0 0 74.15%;
    flex: 0 0 74.15%;
    max-width: 74.15%;
  }
}

@media (min-width: 768px) {
  .col-md-cus-5 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .col-md-4-9 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 44.44444444%;
    -ms-flex: 0 0 44.44444444%;
    flex: 0 0 44.44444444%;
    max-width: 44.44444444%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .col-md-5-9 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 55.55555555%;
    -ms-flex: 0 0 55.55555555%;
    flex: 0 0 55.55555555%;
    max-width: 55.55555555%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .col-md-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: unset;
  }

}
@include media-breakpoint-down(sm){ //Max-width 767.98px

}
@include media-breakpoint-down(md) { //Max width 991.98px
  html {font-size: 52%;}
  body {-webkit-overflow-scrolling: touch;}
  //.breadcrumb 
  .breadcrumb {margin-bottom: 6rem;}
  //Header mobile
  .header-mobile {
    padding: 10px 15px;
    background-color: $white;
    .item-mobile-top {
      position: relative;
      cursor: pointer;
      i {
        color: $theme-color-primary;
        font-size: 24px;
      }
    }

    #_mobile_menutop {
      cursor: pointer;
      i {
        font-size: 24px;
      }
    }
    .item-mobile-top-left{
      @include rtl-margin-right(8px);
    }
  
    #_mobile_search{
      width: 100%;
      @include rtl-margin-lr(18px, 15px);
    }
  }
  
  .mobile-boxpage {
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1000;
    padding-bottom: 48px;
    visibility: hidden;
    @include opacity(0);
    @include transition(all 0.2s);
    &.active {
      visibility: visible;
      @include opacity(1);
      top: 0;
    }
  }
  .content-boxpage {
    padding: 0;
    overflow-y: scroll;
    .box-header {
      height: 45px;
      background: rgba(#fff,0.95);
      @include box-shadow(0 2px 5px -2px rgba(#000,0.2));
      padding: 0 15px;
      position: relative;
      z-index: 1001;
      .title-box {
        font-family: $font-family-primary;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        font-size: 16px;
      }
      .close-box, .back-box{
        font-size: 11px;
        color: $theme-color-primary;
        font-weight: 700;
        cursor: pointer;
        &:hover{
          color: $theme-color-default;
        }
      }
      .back-box {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .box-content {
      padding: 15px;
    }
  }
  #mobile-blockcart{
    .content-boxpage{
      #_mobile_cart{
        .cart_block {
          .cart-block-content{
            box-shadow: none!important;
          }
        }
      }
    }
  }
  #checkout{
    #mobile-pageaccount {
      .account-list-content {
        font-size: 13px;
        padding: 0;
        list-style: none;
        margin: 0;
        li{
          padding-top: 10px;
          padding-bottom: 10px;
        }
        i {
          display: none;
        }
        > div {
          padding: 15px 0;
        }
      }
      .links-currency,.links-language {
        font-size: 13px;
        padding: 15px 0;
        position: relative;
        cursor: pointer;
        i {
          position: absolute;
          @include rtl-right(-20px);
          top: 20px;
        }
      }
      #box-currency, #box-language {
        position: absolute;
        width: 100%;
        top: 45px;
        visibility: hidden;
        @include opacity(0);
        @include translateX(-100%);
        @include transition(all 0.8s ease);
        background: #fff;
        &.active {
          visibility: visible;
          @include opacity(1);
          @include translateX(0);
          height: 100%;
        }
        .item-language, .item-currency {
          padding: 10px 0;
          border-bottom: 1px dotted $base-border-color;
          position: relative;
          &.current {
            &:before {
              position: absolute;
              top: 12px;
              right: 0;
              content: '\f26b';
              font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
              font-size: 14px;
              text-rendering: auto;
              color: $theme-color-default;
            }
          }
        }
      }
    }
  }
  #mobile-pageaccount {
    .account-list-content {
      font-size: 13px;
      padding: 0;
      list-style: none;
      margin: 0;
      li{
        padding-top: 10px;
        padding-bottom: 10px;
      }
      i {
        display: none;
      }
      > div {
        padding: 15px 0;
      }
    }
    .links-currency,.links-language {
      font-size: 13px;
      padding: 15px 0;
      position: relative;
      cursor: pointer;
      i {
        position: absolute;
        @include rtl-right(-20px);
        top: 20px;
      }
    }
    #box-currency, #box-language {
      position: absolute;
      width: 100%;
      top: 45px;
      visibility: hidden;
      @include opacity(0);
      @include translateX(-100%);
      @include transition(all 0.8s ease);
      background: #fff;
      &.active {
        visibility: visible;
        @include opacity(1);
        @include translateX(0);
        height: 100%;
      }
      .item-language, .item-currency {
        padding: 10px 0;
        border-bottom: 1px dotted $base-border-color;
        position: relative;
        &.current {
          &:before {
            position: absolute;
            top: 12px;
            right: 0;
            content: '\f26b';
            font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
            font-size: 14px;
            text-rendering: auto;
            color: $theme-color-default;
          }
        }
      }
    }
  }

  #_mobile_infos {
    position: relative;
    .nav-info {
      cursor: pointer;
      height: 40px;
      line-height: 50px;
      width: 30px;
      position: relative;
      z-index: 4;
      i {font-size: 19px; color: #fff;}
    }
    &.active {
      .nav-info {
        i {
          color: $theme-color-default;
        }
      }
      #_mobile_account_list {
        @include opacity(1);
        display: block;
      }
    }    
  }

  #_mobile_cart {
      .header-cart {
        display: none;
      }
      .blockcart {
        margin: 0;
      }
      .cart_block {
        width: 100%;
        height: auto;
        right: inherit;
        opacity: 1;
        visibility: visible;
        position: relative;
        min-width: inherit;
        padding-top: 0;
        padding-bottom: 0;
        .cart-products-top {
          display: none;
        }
        ul li {
          padding-left: 0;
          padding-right: 0;
        }
        .cart-subtotals, .cart-total {
          padding-left: 20px;
          padding-right: 20px;
        }
      }

  }

  //Mainmenu mobile
  #mobile_top_menu_wrapper {
    overflow: hidden;
    position: fixed;
    z-index: 9999999;
    @include rtl-left(0);
    top: 0px;
    bottom: 0px;
    -webkit-overflow-scrolling: touch;
    width: 250px;
    @include translateX(-250px);
    background: #fff;
    @include transition(all 0.33s cubic-bezier(0.694,0.0482,0.335,1));
    -webkit-overflow-scrolling: touch;
    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      padding: 20px 15px;
    }
    #_mobile_verticalmenu {
      .verticalmenu .menu > li > a {
        font-size: 12px;
        padding: 10px 0;
      }
    }
  }
  #main-site {
    @include transition(all 0.33s cubic-bezier(0.694,0.0482,0.335,1));
  }
  body.open_nov_mobile_menu {
    overflow-x: hidden;
    #main-site {
      @include translateX(250px);
      overflow: hidden;
      position: fixed;
      left:0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 1;
    }
    #mobile_top_menu_wrapper {
      @include translateX(0);
    }
    .canvas-overlay {
      width: 100%;
      height: 100%;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
      @include transition(width 0s 0s,height,0s 0s,opacity .5s 0s);
    }
  }

  //Canvas menu 
  .nov-canvas-menu {
    width: 250px;
    .casvas-content {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      @include rtl-padding(20px, 15px, 20px, 30px);
    }
  }

  .js-top-menu-bottom {
    margin-top: 20px;
    .groups-selector {
      @include rtl-margin-left(0);
    }
  }

  //DisplayTop

  // Home

  //Footer
  .footer {
    .block {
      .title_block {
        margin-bottom: 20px;
      }
    }
  }

  //Page Category
  #nav-top {
    margin-bottom: 20px;
  }
  .filter-button {
    margin-top: 45px;
  }
  .product_list.grid .product-miniature.item-one:hover .product-price-and-shipping {
    opacity: 1;
  }

  //Page detail
  .tabs .nav-tabs {
    overflow: auto;
    padding-bottom: 15px;
    .nav-item {
      display: inline-block;
      white-space: nowrap;
      margin-bottom: 10px;
    }
    .nav-link {
      font-size: 1.6rem;
    }
  }

  //Blog
  .post-page .pagination {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  //left-column, right-column
  .newsletter {
    text-align: center;
  }
  .nov-block_newsletter .block_newsletter .input-group {
    width: 90%;
  }

  #nov-copyright {text-align: center;}
}

@include media-breakpoint-down(lg) { // max 
	.breadcrumb {
    margin-bottom: 6rem;
    .title-breadcrumb{
      font-size: 1.6rem;
      padding: 40px 0;
    }
    .breadcrumb-link{
      padding-top: 20px;
      padding-bottom: 20px;
    }
	}
  .relate-product{
    padding-top: 6rem;
    margin-bottom: 6rem;
  }
  .nivo-controlNav {
    bottom: 25px;
  }

  //Header

  //Footer

	//Product detail
	.product-variants > .product-variants-item select {
		min-width: 6rem;
	}
}

@media (min-width: 768px) and (max-width: 1199px){
  
}

@media(max-width: 767px) and (orientation: landscape) {
  .nov-image {
    text-align: center;
  }
   #stickymenu_bottom_mobile .stickymenu-item {
    width: 100px;
  }
  .footer {
    .col-xs-6 {
      width: 50%;
    }
  }
}
@media(max-width: 767px) {
  //Override bootstrap
  .flex-xs-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .col-xs-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-4 {
    width: 33.3333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  #left-column, #right-column {
    margin-top: 10px;
  }
  .footer {
      margin-bottom: 48px;
  }
  .page-index #header.header-3 {
    margin-bottom: 0;
  }
}
@media (max-width: 425px){
  .group-price{
    @include flex-direction(column);
    @include align-items(flex-start !important);
    .product-comments{
      margin-top: 10px;
    }
  }
  
}
@media (max-width: 575px){
  .page-home{
    
  }
  .text-xs-left{
    text-align: left!important;
  }
  .footer{
    &.footer-two{
      
    }
  }
}

@media (max-width: $screen-xs-max){
  .banner-group{
    @include box-shadow(none!important);
    margin-top: 5px !important;
  }
  #blockcart-modal .modal-body > .row > .col-md-6 {
    padding-top: 0;
  }
  #product #main{
    margin-bottom: 2rem;
  }
  .pagination {
    > div:first-child{
      text-align: center;
      .showing{
        @include rtl-padding-lr(0,0);
        text-align: center;
      }
    }
    > div:last-child {
      text-align: center;
      padding-top: 20px;
      .page-list{
        @include rtl-padding-lr(0,0);
      }
    }
  }
  #stickymenu_bottom_mobile {
    position: fixed;
    width: 100%;
    bottom: 0;
    @include rtl-left(0);
    z-index: 999;
    padding: 8px 15px;
    background: #fff;
    @include box-shadow(0 -2px 5px -2px rgba(#000,0.2));
    .stickymenu-item {
      #back-top{
        cursor: pointer;
      }
      a {display: inline-block;}
      i {
        display: inline-block;
        font-size: 23px;
        color: #000;
        background: transparent;
        width: auto;
        height: auto;
        margin: 0;
        vertical-align: top;
        margin-bottom: 0;
      }
      .icon-settings {
        @include square(17px);
        background: url(../img/icon/icon-settings.png);
        background-size: contain;
        margin-bottom: 4px;
        display: inline-block;
      }
      .icon-house {
        width: 21px;
        height: 18px;
        background: url(../img/icon/icon-home.png);
        background-size: contain;
        margin-bottom: 5px;
        display: inline-block;
      }
      span {
        display: block;
        margin-top: 0;
        line-height: 1;
        color: #bebebe;
        font-size: 10px;
      }
      #_mobile_cart_bottom{
        .blockcart {
          width: 70px;
          margin: auto;
        }
        .header-cart {
          @include flex-direction(column);
          position: relative;
          @include align-items(center);
          margin-bottom: 4px;
          .header-cart-icon{
            position: relative;
            .shopping-cart{
              background: url(../img/icon/cart-mobile.png);
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
              @include square(18px);
              font-size: 0!important;
            }
            .cart-products-count {
              position: absolute;
              top: 0;
              @include rtl-left(60%);
              background: $theme-color-default;
              color: #fff;
              @include border-radius(50%);
              text-align: center;
              @include flexbox();
            }
          }
          .header-cart-value{
            margin-left: 0!important;
            margin-right: 0;
            margin-top: 4px;
            padding: 0;
            display: block!important;
            .value-cart{
              display: none;
            }
          }
        }
      }
      &:hover {
        a {
          color: $theme-color-default;
          i {
            color: $theme-color-default;
          }
        }
      }
      #back-top i {
        margin-bottom: 0;
        margin-top: -3px;
      }
    }
  }
  body#checkout {
    section.checkout-step {
      #customer-form,
      #delivery-address,
      #invoice-address
      {
        .form-group .col-md-8 {
          @include rtl-padding-left(0);
          margin-top: 10px;
        }
      }
      .js-address-form .col-md-10.offset-md-2 {
        @include rtl-padding-left(0);
      }
      .js-address-form{
        @include rtl-padding-left(0);
      }
    }
    .block-form-login {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  //Header
  #header{
    &.header-5{
      background-image: none;
      min-height: auto;
    }
  }
  //Displaytop
  #displayTop{
    &.displaytoptwo{

    }
    &.displaytopthree{
      margin-top: 0;
    }
  }
  //Home
  .page-home{
    .benefits-html{
      padding-top: 60px;
      padding-bottom: 60px;
      .block_content{
        .head-benefit{
          padding-bottom: 60px;
        }
        .main-benefit{
          .img-benefit{
            margin-bottom: 60px;
          }
        }
      }
    }
    .about-us-html{
      padding-top: 60px;
      .content-about{
        margin-bottom: 40px;
      }
    }
    .choose-html{
      padding-top: 60px;
      padding-bottom: 60px;
      .block_content{
        .head-choose-us{
          padding-bottom: 40px;
        }
        .main-choose-us{
          .choose-us-item{
            border-right: none;
            border-bottom: 1px solid #f2f2f2;
            &:last-child{
              border-bottom: none;
            }
          }
        }
      }
    }
    .youtube-main{
      .content-image{
        .modal-youtube{
          .modal-content{
            iframe{
              width: 100%;
            }
          }
        }
      }
    }
    
    &.pagehome-one{
      
    }
    &.pagehome-two{
      .our-featured{
        padding-top: 60px;
        padding-bottom: 60px;
        .block_content{
          .content-our-featured{
            margin-top: 40px;
          }
        }
      }
      .home-about-us{
        padding-top: 60px;
        padding-bottom: 60px;
        .title_block{
          margin-bottom: 40px;
        }
        .about-us-main{
          >p{
            padding-bottom: 42px;
          }
          .read-more{
            padding: 7px 42px;
          }
        }
      }
      .home-choose-us{
        padding-top: 80px;
        padding-bottom: 40px;
        background: #f2f2f2;
        .choose-us-main{
          .content-text-choose{
            padding-bottom: 60px;
          }
          .list-choose-last{
            margin-top: 0;
          }
        }
        .background-row{
          display: none;
        }
        &:after{
          display: none;
        }
      }
      .testimonial-home{
        padding-top: 80px;
        padding-bottom: 160px;
        background: #f5f5f5;
        .nov-html{
          padding-top: 0;
          padding-bottom: 40px;
        }
      }
      .home-image360deg{
        padding-top: 80px;
        padding-bottom: 80px;
      }
      .blog-home{
        .nov-blog{
          padding-bottom: 20px;
        }
      }
      .newsletter-home{
        padding-top: 80px;
        padding-bottom: 80px;
        .title_block{
          margin-bottom: 40px;
          .sub_title{
            line-height: 1.5;
            margin-top: 20px;
          }
        }
      }
    }
    &.pagehome-three{
      .productlist-slider{
        padding-bottom: 20px;
      }
      .testimonial-home{
        padding-top: 150px;
        padding-bottom: 100px;
      }
      .nov-blog{
        padding-top: 80px;
        padding-bottom: 20px;
      }
      .newsletter-home{
        padding-top: 60px;
        padding-bottom: 60px;
        .nov-modules{
          .block{
            .title_block{
              line-height: 1.2;
              margin-bottom: 40px;
              .sub_title{
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }  
  //Footer
  .footer {
    .block {
      .title_block {
        margin-top: 30px;
      }
    }
    .social-copyright{
      margin-bottom: 20px;
    }
    .block_newsletter .input-group .input-group-btn .btn{
      @include rtl-padding-lr(25px, 25px);
    }
    &.footer-one{
      .inner-footer{
        .newsletter-home {
          .nov-modules{
            >.block{
              @include flex-direction(column);
              padding-bottom: 0!important;
              .title_block{
                width: 100%;
                text-align: center;
                margin-bottom: 40px;
              }
              .block_newsletter{
                width: 100%;
                .input-group{
                  margin: auto;
                }
              }
            }
          }
        }
        .footer-center{
          padding-top: 36px;
          padding-bottom: 50px;
        }
      }
      #nov-copyright{
        .image-payment{
          text-align: center;
          padding-bottom: 30px;
        }
      }
    }
    &.footer-two{
      .inner-footer{
        .footer-center{
          padding-top: 60px;
          padding-bottom: 60px;
        }
      }
      #nov-copyright{
        .image-payment{
          text-align: center;
          padding-bottom: 20px;
        }
      }
    }
    &.footer-three{
      .inner-footer{
        .footer-center{
          padding-top: 40px;
          padding-bottom: 60px;
        }
      }
    }
  }
}
@media (max-width: $screen-sm-max){
  .col-md-hidden{
    display: none;
  }
  .group-block{
    padding-top: 50px!important;
    padding-bottom: 50px!important;
  }
  body.open_nov_vertical_menu .canvas-overlay {
    width: 100%;
    height: 100%;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transition: width 0s 0s, height, 0s 0s, opacity 0.5s 0s;
    -moz-transition: width 0s 0s, height, 0s 0s, opacity 0.5s 0s;
    -ms-transition: width 0s 0s, height, 0s 0s, opacity 0.5s 0s;
    -o-transition: width 0s 0s, height, 0s 0s, opacity 0.5s 0s;
    transition: width 0s 0s, height, 0s 0s, opacity 0.5s 0s;
  }
  //Displaytop
  #displayTop{
    
  }
  //HEADER
  #header{

  }
  //Home
  .page-home{
    .practica-content{
      >div{
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 54px;
        }
      }
    }
    .featured-main{
      .content-featured{
        padding-bottom: 40px;
      }
    }
    .youtube-main{
      @include flex-direction(column);
      .content-image{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .youtube-text{
        padding: 60px 15px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        .title_block{
          margin-bottom: 34px;
        }
        .discover{
          margin-top: 30px;
          padding: 7px 20px;
        }
      }
    }
    .nov-manufacture{
      padding-top: 30px;
      padding-bottom: 70px;
    }
    &.pagehome-one{
      .practica{
        padding-top: 60px;
      }
      .featured-html{
        padding-top: 60px;
        padding-bottom: 48px;
      }
      .testimonial-home{
        padding-top: 70px;
        padding-bottom: 50px;
      }
      .home-novgallery{
        padding-top: 74px;
        padding-bottom: 70px;
      }
      .instagram-home{
        padding-top: 70px;
      }
    }
  }
  //Footer
  .footer{
    .data .content-address p{
      @include rtl-padding-lr(0,0);
    }
    .social-copyright{
      ul{
        padding: 0;
        @include justify-content(center!important);
      }
    }
    &.footer-one{
      .inner-footer{
        .newsletter-home{
          .nov-modules{
            >.block{
              padding-top: 70px;
              padding-bottom: 70px;
            }
          }
        }
      }
    }
    &.footer-three{
      .footer-center{
        .social-copyright{
          .list-inline{
            .list-inline-item{
              padding-top: 5px;
              padding-bottom: 5px;
              a{
                border: 1px solid #d7d7d7;
              }
              &:hover{
                a{
                  border-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  } 
}
@media (max-width: $screen-sm-max) and (min-width: $screen-sm){
  
  #header{
    &.header-1{
      .header-top{
        .nov-megamenu{
          .menu{
            >li{
              @include rtl-padding-lr(10px, 10px);
              &:first-child{
                @include rtl-padding-lr(0, 10px);
              }
            }
          }
        }
      }
    }
    &.header-2{
      .header-top{
        .container{
          &:before{
            @include square(140px);
            bottom: calc(100% - 120px);
          }
        }
        .nov-megamenu{
          .menu{
            .item{
              @include rtl-padding-lr(8px, 8px);
              >a{
                font-size: 13px;
              }
              &:first-child{
                @include rtl-padding-lr(0, 8px);
              }
              &:last-child{
                @include rtl-padding-lr(8px!important, 0px);
              }
            }
          }
        }
      }
    }
    &.header-3{
      .header-center{
        background: #f5f5f5;
        .header_search{
          .search-widget{
            .form-search{
              width: 300px!important;
              .ui-autocomplete-input{
                width: 300px!important;
              }
            }
          }
        }
      }
    }
  }

  //Displaytop
  #displayTop{
    &.displaytopthree{
      margin-top: 0;
    }
    
  }
  .page-home{
    .benefits-html{
      padding-top: 80px;
      padding-bottom: 80px;
      .block_content{
        .head-benefit{
          padding-bottom: 80px;
        }
        .main-benefit{
          .img-benefit{
            margin-bottom: 60px;
          }
        }
      }
    }
    .about-us-html{
      padding-top: 80px;
      .content-about{
        margin-bottom: 60px;
      }
    }
    .choose-html{
      padding-top: 80px;
      padding-bottom: 80px;
      .block_content{
        .head-choose-us{
          padding-bottom: 40px;
        }
        .main-choose-us{
          .choose-us-item{
            border-right: none;
            border-bottom: 1px solid #f2f2f2;
            &:nth-child(1),&:nth-child(5), &:nth-child(3){
              border-right: 1px solid #f2f2f2;
            }
            &:nth-child(5), &:nth-child(6){
              border-bottom: none;
            }
            &:nth-child(2){
              border-right: none;
            }
          }
        }
      }
    }
    &.pagehome-one{
      .nov-blog{
        padding-top: 84px;
        padding-bottom: 60px;
      }
      .testimonial-home{
        padding-top: 70px;
      }
    }
    &.pagehome-two{
      .nov-blog{
        padding-bottom: 20px;
        padding-top: 80px;
      }
    }
    &.pagehome-three{
      .productlist-slider{
        padding-bottom: 30px;
      }
      .testimonial-home{
        padding-top: 150px;
        padding-bottom: 110px;
      }
      .nov-blog{
        padding-top: 80px;
        padding-bottom: 50px;
      }
      .newsletter-home{
        padding-top: 80px;
        padding-bottom: 80px;
      }
    }
  }

  //FOOTER
  .footer{
    &.footer-one{
      .inner-footer {
        .newsletter-home .nov-modules{
          >.block{
            padding-top: 60px;
            padding-bottom: 0px;
          }
        }
        .footer-center{
          padding-top: 60px;
          padding-bottom: 60px;

        }
      }
    }
    &.footer-three{
      .inner-footer{
        .footer-center{
          padding-top: 80px;
        }
      }
    }
  }
}
@media (max-width: $screen-md-max) and (min-width: $screen-md){
  //HEADER
  #header{
    &.header-2{
      .header-top{
        .nov-megamenu{
          .menu{
            .item{
              @include rtl-padding-lr(10px, 10px);
              >a{
                font-size: 15px;
              }
              &:first-child{
                @include rtl-padding-lr(0, 10px);
              }
              &:last-child{
                @include rtl-padding-lr(10, 0px);
              }
            }
          }
        }
      }
    }
    &.header-3{
      .header-center .nov-megamenu .menu > .item > .dropdown-menu{
        width: 630px!important;
      }
    }
  }
  
  //Displaytop
  #displayTop{
    
  }

  //PageHome
  .page-home{
    .about-us-html{
      padding-top: 80px;
      .main-about{
        .content-about{
          .title-about{
            margin-bottom: 40px;
          }
          .button-about{
            padding: 15px 40px;
            font-size: 1.5rem;
            margin-top: 4rem;
          }
        }
      }
    }
    .practica-content{
      >div{
        margin-bottom: 40px;
        &:last-child{
          margin-bottom: 64px;
        }
      }
    }
    .featured-main{
      .content-featured{
        padding-bottom: 40px;
      }
    }
    .youtube-main{
      .youtube-text{
        padding: 0 15px;
        .title_block{
          margin-bottom: 34px;
        }
        .discover{
          margin-top: 10px;
          padding: 7px 20px;
        }
      }
    }
    .nov-manufacture{
      padding-bottom: 90px;
    }
    &.pagehome-one{
      .practica{
        padding-top: 80px;
      }
      .featured-html{
        padding-top: 80px;
        padding-bottom: 68px;
      }
      .testimonial-home{
        padding-top: 90px;
        padding-bottom: 70px;
      }
      .instagram-home{
        padding-top: 90px;
      }
    }
    &.pagehome-two{
      .nov-blog{
        padding-bottom: 60px;
        padding-top: 80px;
      }
    }
    &.pagehome-three{
      .productlist-slider{
        padding-bottom: 80px;
      }
      .nov-blog{
        padding-bottom: 60px;
      }
    }
  }
  //Footer
  .footer{
    &.footer-one{
      .inner-footer{
        .newsletter-home{
          .nov-modules{
            >.block{
              padding-top: 70px;
              padding-bottom: 70px;
            }
          }
        }
      }
    }
    &.footer-three{
      .inner-footer{
        .footer-center{
          .social-copyright{
            margin-top: 29px!important;
            .list-inline{
              .list-inline-item{
                padding-top: 5px;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: $screen-md-max) and (min-width: $screen-sm){

  #displayTop{
    &.displaytopone, &.displaytoptwo, &.displaytopthree{
      .slider-wrapper{
        .nivoSlider{
          .nivo-directionNav{
            .nivo-prevNav{
              left: 20px;
            }
            .nivo-nextNav{
              right: 20px;
            }
          }
        }
      }
    }
  }
  .page-home{
    &.pagehome-two{
      .policy-home{
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .our-featured{
        padding-top: 80px;
        padding-bottom: 80px;
      }
      .home-about-us{
        padding-top: 80px;
        padding-bottom: 80px;
      }
      .home-choose-us{
        padding-top: 80px;
        padding-bottom: 66px;
      }
      .home-image360deg{
        padding-top: 80px;
        padding-bottom: 80px;
      }
      
      .newsletter-home{
        padding-top: 80px;
        padding-bottom: 80px;
      }
    }
  }
  #header{
    &.header-1{
      
      .header-top{
        >.container{
          width: 100%;
          max-width: 100%;
        }
        .group-header-list{
          .header_search{
            .search-widget{
              .toggle-search{
                @include flexbox();
                cursor: pointer;
              }
              .form-search{
                position: absolute;
                @include transition(all 0.5s ease);
                top: 50%;
                @include translateY(-50%);
                @include rtl-right(0);
                @include opacity(0);
                visibility: hidden;
                .ui-autocomplete-input{
                  @include border-radius(40px);
                }
              }
              &.active-search{
                .toggle-search{
                  @include opacity(0);
                }
                .form-search{
                  @include opacity(1);
                  visibility: visible;
                  @include rtl-right(190px);
                }
              }
            }
          }
        }
      }
    }
    &.header-2{
      .header_search{
        .search-widget{
          .toggle-search{
            @include flexbox();
            cursor: pointer;
          }
          .form-search{
            position: absolute;
            @include transition(all 0.5s ease);
            top: 50%;
            @include translateY(-50%);
            @include rtl-right(0);
            @include opacity(0);
            visibility: hidden;
            .ui-autocomplete-input{
              @include border-radius(40px);
            }
          }
          &.active-search{
            .toggle-search{
              @include opacity(0);
            }
            .form-search{
              @include opacity(1);
              visibility: visible;
              @include rtl-right(190px);
            }
          }
        }
      }
    }
  }
  .footer{
    &.footer-one{
      .inner-footer{
        .footer-center{
          .social-copyright{
            margin-top: 19px!important;
            margin-bottom: 0;
            .list-inline{
              .list-inline-item{
                padding-top: 5px;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
    &.footer-two{
      .inner-footer{
        .footer-center{
          padding-top: 80px;
          padding-bottom: 60px;
        }
      }
    }
  }
}
@media (max-width: $screen-md-max){
  .pagehome-three{
    .blog-home{
      .list_style{
        .novblog-box-content{
          .owl-nav{
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 1439px) and (min-width: $screen-sm){
  .page-home{
    &.pagehome-two{
      .home-choose-us{
        background: #f5f5f5;
        &:after{
          display: none;
        }
      }
    }
  }
}
@media (max-width: 1439px){
  .page-home{
    &.pagehome-three{
      .choose-html{
        &:before, &:after{
          display: none;
        }
      }
    }
  }
}
@media (max-width: 1600px) and (min-width: 1440px){
  .page-home.pagehome-two .home-choose-us:after{
    @include rtl-left(-70px);
  }
  .page-home{
    &.pagehome-three{
      .choose-html{
        &:before{
          @include rtl-left(-44px);
        }
        &:after{
          @include rtl-right(-120px);
        }
      }
    }
  }
}