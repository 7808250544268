// Product tabs Categories
.product-tabs-categories {
	padding-top: 130px;
	padding-bottom: 130px;
	overflow: hidden;
	.group-title{
		padding: 20px;
		@include title_block_1();
	}
	.product_tab_content{
		.tab-pane{
			.product_list{
				.owl-stage-outer{
					overflow: initial;
				}
				.owl-nav{
					@include opacity(0);
					display: block;
					[class*='owl-']{
						@include square(40px);
						@include border-radius(2px);
						@include transition(all 0.5s ease);
						border: 1px solid #f2f2f2;
						top: calc(50% - 75px);
						background: $white;
						color: $theme-color-primary;
						&:hover{
							border-color: $theme-color-default;
							background: $theme-color-default;
						}
					}
					.owl-prev{
						@include rtl-left(5px);
					}
					.owl-next {
						@include rtl-right(5px);
					}
				}
				&:hover{
					.owl-nav{
						@include opacity(1);
					}
				}
			}
		}
	}
	.nav-tabs {
	    border: none;
	    padding: 0;
	    margin-bottom: 8px;
	    .nav-item {
			cursor: pointer;
			margin-bottom: 0;
			.nav-link {
				border: none;
				background: transparent;
				font-size: 18px;
				color: #999999;
				@include border-radius(0);
				padding: 9px 20px;
				text-transform: capitalize;
				font-weight: 500;
				line-height: 1;
				white-space: nowrap;
				@include transition(all 0.35s ease);
				&:hover, &:focus {
					color: $white;
					background: $theme-color-default;
					@include border-radius(16px);

				}
				&.active {
					color: $white;
					background: $theme-color-default;
					@include border-radius(16px);
				}
			}
		}
	}
	@media (max-width: $screen-sm-max){
		padding-top: 80px;
		padding-bottom: 80px;
	}
	@media (max-width: $screen-xs-max){
		.block_content{
			.group-title{
				.title_block{
					span{
						font-size: 2.8rem!important;
					}
				}
				.nav-tabs{
					width: 100%;
					overflow-x: auto;
				}
			}
		}
	}
	@media (max-width: 575px){
		.block_content{
			.group-title{
				.title_block{
					span{
						&:before, &:after{
							display: none;
						}
					}
				}
			}

		}
	}
}

// Product Tabs
.nov-producttab{
	overflow: hidden;
	padding-top: 130px;
	padding-bottom: 130px;
	.nav-tabs {
	    border: none;
		padding: 0;
		margin-bottom: 60px;
		position: relative;
		@include rtl-padding-lr(40px, 40px);
		&:before, &:after{
			content: "";
			position: absolute;
			top: 50%;
			@include translateY(-50%);
			width: 74px;
			height: 10px;
		}
		&:before{
			@include rtl-right(100%);
			background-image: url('../img/icon-title.png');
			background-repeat: no-repeat;
			background-position: 0 -3px;
		}
		&:after{
			@include rtl-left(100%);
			background-image: url('../img/icon-title.png');
			background-repeat: no-repeat;
			background-position: -86px -3px;
		}
	    .nav-item {
			position: relative;
			@include rtl-margin-lr(0,10px);
			cursor: pointer;
			white-space: nowrap;
			margin-bottom: 0;
			&:last-child {
				@include rtl-margin-lr(0,0);
			}
		}
		.nav-link {
			border: none;
			background: #f2f2f2;
			color: $theme-color-primary;
			font-size: 18px;
			font-weight: 500;
			border: none;
			@include border-radius(45px);
			padding: 15px 23px;
			line-height: 1;
			@include transition(all 0.35s ease);
			&:hover, &:focus, &.active {
				color: $white;
				background: $theme-color-default;
			}
		}
	}
	.block_content{
		.product_tab_content{
			margin: -20px;
			.product_list{
				padding: 20px;
				.owl-stage-outer{
					overflow: initial;
				}
				.owl-nav{
					.owl-prev{
						@include rtl-left(20px);
					}
					.owl-next{
						@include rtl-right(20px);
					}
				}
			}
		}	
	}
	@media (max-width: $screen-sm-max){
		padding-top: 80px;
		padding-bottom: 80px;
	}
	@media (max-width: $screen-xs-max){
		.block{
			.group-title{
				@include flex-direction(column);
				@include justify-content(flex-start !important);
				@include align-items(flex-start !important);
				.title_block{
					margin-bottom: 40px;
				}
				.nav-tabs{
					width: 100%;
					overflow-x: auto;
					padding: 0;
					margin-bottom: 40px;
					&:before, &:after{
						display: none;
					}
				}
			}
		}
	}
}
// Product List
.nov-productlist {
	&.productlist-slider {
		.block-product{
			padding-top: 120px;
			.title_block{
				margin-bottom: 48px;
			}
			.block_content{
				margin: 0 -50px 0 -50px;
				overflow: hidden;
				padding-bottom: 100px;
				.product_list {
					@include rtl-padding-lr(50px, 50px);
					.owl-nav{
						@include opacity(1);
						[class*='owl-']{
							
						}
						.owl-prev{
							@include rtl-left(-21px);
						}
						.owl-next{
							@include rtl-right(-21px);
						}
					}
					.owl-dots{
						@include rtl-left(50%);
						@include translateX(-50%);
						top: calc(100% + 40px);
						.owl-dot{
							@include flexbox();
							@include align-items(center);
							@include justify-content(center);
							span{
								margin: 0 5px;
								@include border-radius(2px);
								background: $theme-color-primary;
								&:hover{
									background: $theme-color-default;
								}
							}
							&.active{
								span{
									background: $theme-color-default;
								}
							}
						}
					}
				}
			}
			@media (max-width: 1300px) and (min-width: 1200px){
				.block_content{
					margin: 0 -10px;
					.product_list{
						padding-left: 10px;
						padding-right: 10px;
						.owl-nav{
							[class*='owl-']{
								font-size: 2.5rem;
								cursor: pointer;
							}
							.owl-prev{
								@include rtl-left(15px);
							}
							.owl-next{
								@include rtl-right(15px);
							}
						}
					}
				}
			}
			@media (max-width: $screen-md-max) and (min-width: $screen-md){
				padding-top: 90px;
				.block_content{
					margin: 0 -10px;
					padding-bottom: 70px;
					.product_list{
						padding-left: 10px;
						padding-right: 10px;
						.owl-nav{
							[class*='owl-']{
								font-size: 2rem;
							}
							.owl-prev{
								@include rtl-left(15px);
							}
							.owl-next{
								@include rtl-right(15px);
							}
						}
					}
				}
			}
			@media (max-width: $screen-sm-max) and (min-width: $screen-sm){
				padding-top: 70px;
				.block_content{
					padding-bottom: 50px;
					margin: 0 -30px;
					.product_list{
						padding:  20px 30px;
						.owl-nav{
							[class*='owl-']{
								font-size: 2rem;
							}
							.owl-prev{
								@include rtl-left(0px);
							}
							.owl-next{
								@include rtl-right(0px);
							}
						}
					}
				}
			}
			@media (max-width: $screen-xs-max){
				padding-top: 70px;
				.block_content{
					padding-bottom: 50px;
					.product_list{
						.owl-nav{
							[class*='owl-']{
								font-size: 2rem;
							}
							.owl-prev{
								@include rtl-left(15px);
							}
							.owl-next{
								@include rtl-right(15px);
							}
						}
					}
				}
			}
		}
	}
	&.productlist-liststyle {
		.block-product{
			overflow: hidden;
			.product_list{
				.owl-nav{
					display: none;
				}
			}
		}
		.item-list {
			.product-miniature {
				border: none;
				&.first_item{
					margin-top: 0; 
				}
				.thumbnail-container{
					width: 100px;
					height: 100px;
					max-width: 100px;
					min-width: 100px;	
					@include border-radius(5px);
					@include rtl-margin-lr(0, 15px);
					position: relative;
					border: 1px solid #f2f2f2;
				}
				.product-description{
					padding: 0;
					padding-bottom: 30px;
					position: relative;
					border: none;
					margin: 0;
					.product-title {
						padding-top: 0;
						padding-bottom: 10px;
						a{
							font-size: 14px;
							font-weight: 500;
							color: $product-title;
							&:hover{
								color: $theme-color-default;
							}
						}
					}
					.product-comments {
						padding-top: 0px;
						margin-bottom: 0px;
						padding-bottom: 18px;
						border-bottom: 1px dashed #f2f2f2;
						.star_content{
							@include justify-content(flex-start);
						}
					}
					.group-price{
						@include transition(all 0.35s ease);
						@include opacity(1);
						.product-group-price{
							padding-top: 18px;
						}
					}
				}
				&:hover{
					.thumbnail-container{
						
					}
					.product-description{
						
					}
				}
			}
			@media(max-width: 374px){
				.product-miniature{
					.content-list-product{
						@include flex-direction(column);
						.thumbnail-container{
							margin: 0;
							width: 100%;
							height: auto;
							max-width: 100%;
						}
						.product-description{
							width: 100%;
							padding-top: 20px;
							padding-bottom: 35px;
							text-align: center;
							.group-price{
								.product-group-price{
									@include rtl-text-align-left();
								}
							}
						}
					}
				}
			}
		}
	}

}

// Productlist Deal List
.productslist-deal-list {
	.title_block {
		font-size: 23px;
		line-height: 1;
		margin-bottom: -25px;
		position: relative;
		z-index: 2;
		span {
			background: $theme-color-default;
			@include border-radius(25px);
			padding: 14px 35px;
			color: #fff;
		}
	}
	.item {
		.product-miniature {
			-webkit-box-align: center !important;
			-webkit-align-items: center !important;
			-ms-flex-align: center !important;
			align-items: center !important;
			border: none;
			&:hover {
				border: none;
			}
		}
	}
	.product_list {
		border: 1px solid $base-border-color;
		
	}

	// -----------------------------
	.owl-stage-outer {
		@include rtl-margin-left(-1px);
		.owl-item {
		    .item {
		      @include rtl-border-left(1px solid $base-border-color);
		    }
	  	}
	}
	.owl-theme {
		.owl-nav {
			[class*="owl-"] {
				top: 50%;
				margin-top: -15px;
				@include opacity(0);
				@include transition(all 0.35s);
			}
			.owl-next {
				@include rtl-right(-15px);
			}
			.owl-prev {
				@include rtl-left(-15px);
			}
		}
	}
	&:hover {
		.owl-theme .owl-nav [class*="owl-"] {
		  @include opacity(1);
		}
	}
}
//Manufacture
.nov-manufacture {
	padding: 0px 15px 120px 15px;
  .block_content {
    overflow: hidden;
    .manufacture{
    	.owl-stage-outer{
    		overflow: initial;
    	}
    }
    .logo-manu {
    	text-align: center;
    	a{
    		display: block;
    		overflow: hidden;
    	}
    }
  }
  @media(max-width: $screen-sm-max){
  	padding: 80px 15px;
  }
}
//NOV_GALLERY
.nov-gallery-scroll{
	.title_block{
		color: $white;
		margin-bottom: 30px;
	}
	.block_content {
    	position: relative;
	    width: 100%;
	    overflow-x: scroll;
	    white-space: nowrap;
	    transition: all 0.2s;
	    will-change: transform;
	    user-select: none;
	    cursor: pointer;
	    padding: 40px 0;
	    &::-webkit-scrollbar {
	    	height: 8px;
		    background-color: #404040;
		    @include border-radius(20px);
	    }
	    &::-webkit-scrollbar-thumb {
	    	@include border-radius(20px);
		    background-color: $theme-color-default;
	    }
	    &::-webkit-scrollbar-track {
    	    background-color: #404040;
		    @include border-radius(20px);
	    }
	    .image {
	    	position: relative;
	    }
	    .gallery-scroll__item {
	    	display: inline-block;
	        margin: 0 3.4px;
        	position: relative;
    		@include transition(all 0.4s ease);
    		@include scale(1, 1);
    		z-index: 1;
    		img {
    			width: auto;
			    height: 270px;
			    cursor: pointer;
			    &:hover {
			    	filter: brightness(50%);
			    }
    		}
        	.icon-link {
        		position: absolute;
        		top: 0;
				bottom: 0;
				display: block;
				@include transition(all 0.4s ease);
				margin: auto;
				@include square(40px);
				background: $white;
				@include border-radius(100%);
				@include box-shadow(0 0 0 5px rgba(255,255,255,0.3));
				left: 0;
				right: 0;
				text-align: center;
				line-height: 40px;
				@include opacity(0);
				z-index: 1;
				font-size: 1.4rem;
				font-weight: 400;
				color: $theme-color-default;
				@include scale(0, 0);
				&:hover {
					background: $theme-color-default;
				    color: $white;
				}
        		&:before {
					content: "\F07B";
					font-family: "fontello";
					@include rtl-margin-left(0);
	        	}
	        }
        	&:after {
				content: '';
				display: block;
				@include transition(all 0.4s ease);
				position: absolute;
				margin: auto;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(0,0,0,0.7);
				@include opacity(0);
        	}
        	&:hover {
    			@include scale(1.15, 1.15);
    			@include transition(all 0.4s ease);
    			@include box-shadow(0px 10px 15px rgba(1,1,1,0.8));
    			z-index: 2;
        		.icon-link {
				    @include opacity(1);
				    @include scale(1, 1);
        		}
        		&:after {
        			@include opacity(1);
        		}
        	}
	    }
	}
}
.modal_gallery {
	display: none;
    position: fixed;
    z-index: 999;
    @include rtl-left(0);
    top: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.9);
}
.modal-content_gallery {
    margin: auto;
    display: block;
    max-height: 700px;
    margin: 0 auto;
    margin-top: 50vh;
    @include translate(0, -50%);
}
.close_gallery {
    margin: 0 10px 0 0;
    color: $theme-color-default;;
    font-size: 4rem;
    font-weight: bold;
    float: right;
    line-height: 1;
    text-shadow: 0 1px 0 $theme-color-default;;
    @include opacity(1);
    &:hover, &:focus {
    	color: $theme-color-default !important;
	    text-decoration: none;
	    cursor: pointer;
    }
}

//nov-image360deg
.nov-image360deg{
	.content-nov-image360deg{
		.title_block{
			margin-bottom: 60px;
		}
		.sub_title{
			max-width: 820px;
			margin: 0 auto;
			font-size: 14px;
			color: $theme-color-primary;
			line-height: 24px;
			text-align: center;
			padding-bottom: 42px;
		}
		.main-nov-image360deg{
			#nov-image360deg{
				margin: 0 auto;
				z-index: 2;
			}
			.button_group{
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				position: relative;
				padding-top: 45px;
				.button{
					width: 50px;
					height: 50px;
					@include flexbox();
					@include align-items(center);
					@include justify-content(center);
					background: $white;
					@include border-radius(50%);
					@include box-shadow(0 0 4.75px 0.25px rgba(0,0,0,0.05));
					@include transition(all 0.35s ease);
					z-index: 2;
					i{
						color: $theme-color-default;
						font-size: 18px;
						display: inline-block;
					}
					&:hover{
						background: $theme-color-default;
						@include box-shadow(none);
						i{
							color: $white;
						}
					}
				}
				.button_next_360deg{
					@include rtl-margin-lr(15px, 15px);
					i{
						@include rotate(210deg);
					}
				}
				.button_prev_360deg{
					@include rtl-margin-lr(15px, 15px);
					i{
						@include rotate(150deg);
					}
				}
				.player_360deg{
					position: relative;
					width: 50px;
					height: 50px;
					.button_play_360deg{
						position: absolute;
						top: 0;
						@include rtl-left(0);
						@include opacity(1);
						visibility: visible;
						margin: 0;
					}
					.button_stop_360deg{
						position: absolute;
						top: 0;
						@include rtl-left(0);
						@include opacity(0);
						visibility: hidden;
						margin: 0;
					}
					&.active {
						.button_play_360deg {
							@include opacity(0);
							visibility: hidden;
						}
						.button_stop_360deg {
							@include opacity(1);
							visibility: visible;
						}
					}
				}
				&:after{
					content: "";
					position: absolute;
					bottom: 25px;
					@include rtl-left(50%);
					@include translateX(-50%);
					width: 83%;
					height: 160px;
					@include border-radius(50%);
					border: 1px solid #f6f6f6;
					z-index: 0;
				}
			}
		}
		@media (max-width: $screen-xs-max) and (min-width: 576px){
			.main-nov-image360deg{
				#nov-image360deg{
					max-width: 510px!important;
					max-height: 517px!important;
				}
			}
			.button_group{
				&:after{
					height: 120px;
				}
			}	
		}
		@media (max-width: 575px) and (min-width: 425px){
			.main-nov-image360deg{
				#nov-image360deg{
					max-width: 350px!important;
					max-height: 358px!important;
				}
				.button_group{
					&:after{
						height: 120px;
					}
				}
			}	
		}
		@media (max-width: 424px){
			.main-nov-image360deg{
				#nov-image360deg{
					max-width: 290px!important;
					max-height: 294px!important;
				}
				.button_group{
					&:after{
						height: 80px;
					}
				}
			}	
		}
	}
}
// Testimonial
.content-testimonial-main{
	margin: -0px -50px -10px -50px;
	.testimonial-type-one{
		padding: 0px 50px 10px 50px;
	}
	@media (max-width: 1300px) and (min-width: 1200px){
		margin: -0px -30px -10px -30px;
		.testimonial-type-one{
			padding: 0px 30px 10px 30px;
			.owl-nav{
				[class*='owl-']{
					font-size: 2.5rem;
					display: none;
				}
			}
		}
	}
	@media(max-width: $screen-md-max){
		margin: -0px -30px -10px -30px;
		.testimonial-type-one{
			padding: 0px 30px 10px 30px;
			.owl-nav{
				[class*='owl-']{
					font-size: 2rem;
				}
				.owl-prev{
					@include rtl-left(0px!important);
				}
				.owl-next{
					@include rtl-right(0px!important);
				}
			}
		}
	}
}
.content-testimonial-main-two{
	margin: 0px -25px -83px -25px;
	.testimonial-type-two{
		padding: 0px 25px 83px 25px;
	}
	@media (max-width: 1300px) and (min-width: 992px){
		margin: 0px -10px -83px -10px;
		.testimonial-type-two{
			padding: 0px 10px 83px 10px;
		}
	}
}
.content-testimonial-main-three{
	margin: -50px -25px -63px -25px;
	.testimonial-type-three{
		padding: 50px 25px 63px 25px;
	}
	
}
#testimonial_block {
  	&.testimonial-type-one{
  		overflow: hidden;
  		.owl-stage-outer{
  			overflow: initial;
  		}
  		.type-one{
  			background: $white;
  			@include border-radius(8px);
  			@include transition(all 0.35s ease);
  			.box-image-testimonial{
  				@include flexbox();
  				@include align-items(center);
  				@include justify-content(flex-start);
  				@include rtl-margin-lr(20px, 20px);
  				padding-top: 30px;
  				padding-bottom: 38px;
  				border-bottom: 1px dashed #d7d7d7;
  				.testimonial-avarta{
  					width: 100px;
  					height: 100px;
  					min-width: 100px;
  					@include border-radius(50%);
  					@include rtl-margin-lr(0, 30px);
  					img{
  						@include border-radius(50%);
  					}
  				}
  				.group-info-testimonial{
  					h3{
  						font-size: 16px;
  						font-weight: 700;
  						color: $theme-color-primary;
  						margin-bottom: 7px;
  					}
  					.box-company{
  						font-size: 13px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						margin-bottom: 0;
  					}
  				}
  			}
  			.testimonial-content{
  				@include rtl-margin-lr(20px, 20px);
  				padding-top: 34px;
  				padding-bottom: 53px;
  				.title-testimonial{
  					font-size: 16px;
  					font-weight: 700;
  					color: $theme-color-primary;
  				}
  				.nov-icon-testimonial{
  					width: 76px;
  					height: 14px;
  					background-image: url('../img/icon-reviews-testimonial.png');
  					background-repeat: no-repeat;
  					display: inline-block;
  					margin-top: 12px;
  					margin-bottom: 18px;
  				}
  				.text{
  					p{
  						font-size: 13px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						line-height: 1.7;
  						margin-bottom: 0;
  					}
  				}
  			}
  			&:hover{
  				@include box-shadow(4px 4px 0 0 rgba($theme-color-default, 1));
  			}
  		}
  		.owl-nav{
  			.owl-prev{
  				@include rtl-left(-21px);
  			}
  			.owl-next{
  				@include rtl-right(-21px);
  			}
  		}
  	}
  	&.testimonial-type-two{
  		overflow: hidden;
  		.owl-stage-outer{
  			overflow: initial;
  		}
  		.type-two{
  			background: $white;
  			@include border-radius(8px);
  			@include transition(all 0.35s ease);
  			.box-image-testimonial{
  				@include flexbox();
  				@include align-items(center);
  				@include justify-content(flex-start);
  				@include rtl-margin-lr(20px, 20px);
  				padding-top: 30px;
  				padding-bottom: 38px;
  				border-bottom: 1px dashed #d7d7d7;
  				.testimonial-avarta{
  					width: 100px;
  					height: 100px;
  					min-width: 100px;
  					@include border-radius(50%);
  					@include rtl-margin-lr(0, 30px);
  					img{
  						@include border-radius(50%);
  					}
  				}
  				.group-info-testimonial{
  					h3{
  						font-size: 16px;
  						font-weight: 700;
  						color: $theme-color-primary;
  						margin-bottom: 7px;
  					}
  					.box-company{
  						font-size: 13px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						margin-bottom: 0;
  					}
  				}
  			}
  			.testimonial-content{
  				@include rtl-margin-lr(20px, 20px);
  				padding-top: 34px;
  				padding-bottom: 53px;
  				.title-testimonial{
  					font-size: 16px;
  					font-weight: 700;
  					color: $theme-color-primary;
  				}
  				.nov-icon-testimonial{
  					width: 76px;
  					height: 14px;
  					background-image: url('../img/icon-reviews-testimonial.png');
  					background-repeat: no-repeat;
  					display: inline-block;
  					margin-top: 12px;
  					margin-bottom: 18px;
  				}
  				.text{
  					p{
  						font-size: 13px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						line-height: 1.7;
  						margin-bottom: 0;
  					}
  				}
  			}
  			&:hover{
  				@include box-shadow(4px 4px 0 0 rgba($theme-color-default, 1));
  			}
  		}
  		.owl-dots{
  			top: calc(100% - 15px);
  			@include rtl-left(165px);
  			.owl-dot{
  				span{
  					width: 12px;
  					height: 12px;
  					background: #8bddfb;
  					@include transition(width 0.35s ease);
  					@include border-radius(12px);
  				}
  				&.active{
  					span{
  						background: $theme-color-default;
  						width: 50px;
  					}
  				}
  				&:hover{
  					span{
  						background: $theme-color-default;
  					}
  				}
  			}
  		}
  	}
  	&.testimonial-type-three{
  		overflow: hidden;
  		.owl-stage-outer{
  			overflow: initial;
  		}
  		.type-three{
  			width: 80%;
  			margin: 0 auto;
  			background: $white;
  			@include border-radius(8px);
  			@include transition(all 0.35s ease);
  			@include box-shadow(4px 4px 0 0 rgba($theme-color-default, 1));
  			.box-image-testimonial{
  				@include flexbox();
  				@include align-items(center);
  				@include justify-content(flex-start);
  				@include flex-direction(column);
  				padding-top: 75px;
  				padding-bottom: 38px;
  				border-bottom: 1px dashed #d7d7d7;
  				position: relative;
  				.testimonial-avarta{
  					width: 100px;
  					height: 100px;
  					min-width: 100px;
  					@include border-radius(50%);
					position: absolute;
					top: -50px;
  					img{
  						@include border-radius(50%);
  					}
  				}
  				.group-info-testimonial{
  					text-align: center;
  					h3{
  						font-size: 16px;
  						font-weight: 700;
  						color: $theme-color-primary;
  						margin-bottom: 7px;
  					}
  					.box-company{
  						font-size: 13px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						margin-bottom: 0;
  					}
  				}
  			}
  			.testimonial-content{
  				@include rtl-margin-lr(20px, 20px);
  				padding-top: 35px;
  				padding-bottom: 53px;
  				text-align: center;
  				position: relative;
  				&:before{
  					content: "";
  					position: absolute;
  					top: -17px;
  					right: -20px;
					width: 50px;
					height: 28px;
					background-image: url('../img/icon-cut-testimonial.jpg');
					background-repeat: no-repeat;
  				}
  				.title-testimonial{
  					font-size: 16px;
  					font-weight: 700;
  					color: $theme-color-primary;
  				}
  				.nov-icon-testimonial{
  					width: 76px;
  					height: 14px;
  					background-image: url('../img/icon-reviews-testimonial.png');
  					background-repeat: no-repeat;
  					display: inline-block;
  					margin-top: 12px;
  					margin-bottom: 18px;
  				}
  				.text{
  					max-width: 570px;
  					margin: 0 auto;
  					p{
  						font-size: 13px;
  						font-weight: 400;
  						color: $theme-color-primary;
  						line-height: 1.7;
  						margin-bottom: 0;
  					}
  				}
  			}
  		}
  		.owl-dots{
  			top: calc(100% - 15px);
  			@include rtl-left(50%);
  			@include translateX(-50%);
  			.owl-dot{
  				span{
  					width: 12px;
  					height: 12px;
  					background: #8bddfb;
  					@include transition(width 0.35s ease);
  					@include border-radius(12px);
  				}
  				&.active{
  					span{
  						background: $theme-color-default;
  						width: 50px;
  					}
  				}
  				&:hover{
  					span{
  						background: $theme-color-default;
  					}
  				}
  			}
  		}
  	}
}

// Groupproductlist
.groupproductlist {
	padding-bottom: 68px;
	.block_content{
		position: relative;
		margin: 0 -20px;
		.effect{
			overflow: hidden;
			position: relative;
			@include transition(all 0.35s ease);
			&:hover{
				img{
					-webkit-filter: grayscale(100%);
					filter: grayscale(100%);
				}
			}
		}
		.title_block{
			margin-bottom: 41px!important;
		}
		.product_list_content{
			overflow: hidden;
			margin: 0 -20px;
			
			.product_list{
				.owl-stage-outer{
					overflow: initial;
				}
				.owl-nav{
					display: none;
				}
			}
		}
	}
	@media (max-width: $screen-sm-max) and (min-width: $screen-sm){
		padding-bottom: 28px;
	}
	@media (max-width: $screen-xs-max){
		padding-bottom: 28px;
		.block_content{
			.title_block{
				margin-bottom: 11px!important;
			}
		}
	}
}

//nov-block_newsletter
.nov-block_newsletter {
	.title_block {
		text-align: center;
		font-size: 2.4rem!important;
		color: $theme-color-primary!important;
		margin-bottom: 28px!important;
		.sub_title{
			font-size: 1.2rem;
			color: $text-color;
			font-weight: 400;
			text-transform: initial;
			margin-top: 22px;
			line-height: normal;
		}
	}
	.block_newsletter {
		p {display: none;}
		.input-group {
			width: 570px;
			margin: 0 auto;
			@include border-radius(23px);
			input {
				background: #f3f3f3;
				border-color: #f3f3f3;
				border: none;
				background: $white;
			}
		}
		button {
			background: $theme-color-primary;
			border-color: $theme-color-primary;
			@include transition(all 0.35s ease);
			&:hover{
				background: $theme-color-default;
				border-color: $theme-color-default;
			}
		}

	}
	.social-content{
		.title_block{
			display: none!important;
		}
		#social_block{
			margin-top: 30px;
		}
	}
}

.nov-countdown-productlist {
	padding-top: 120px;
	padding-bottom: 120px;
	.block-product{
		overflow:hidden;
	}
	.block_content{
		margin: 0;
		.owl-theme{
			.owl-stage-outer{
				overflow: initial;
			}
		}
		.product-miniature{
			overflow: initial;
			@include transition(all 0.35s ease);
			padding-bottom: 65px;
			.thumbnail-container{
				.product-thumbnail{
					position: relative;
					@include border-radius(4px);
					margin-top: 50px;
					@include rtl-margin-lr(84px, 0px);
					img{
						@include border-radius(4px);
					}
					&:before{
						content: "";
						position: absolute;
						top: -50px;
						@include rtl-left(-100px);
						border: 8px solid $theme-color-default;
						width: 100%;
						height: 100%;
						@include border-radius(4px);
						z-index: -1;
					}
				}
			}
			.product-description{
				position: relative;
				padding-top: 50px;
				padding-bottom: 0;
				.group-buttons{
					@include justify-content(flex-start !important);
					position: relative;
					@include rtl-left(0);
					@include translateX(0);
					margin-top: 26px;
					top: 0;
					>a, form{
						position: relative;
						left: initial;
						right: initial;
						top: initial;
						@include opacity(1);
					}
					.quick-view{
						@include rtl-margin-lr(10px, 10px);
					}
				}
				.product-title{
					padding-top: 40px;
					padding-bottom: 21px;
					@include rtl-text-align-left();
					>a{
						line-height: 26px;
					}
				}
				.product-comments{
					padding-bottom: 13px;
					.star_content{
						@include justify-content(flex-start !important);
					}
				}
				.product-desc{
					font-size: 13px;
					color: #505050;
					font-weight: 400;
					line-height: 22px;
					padding-top: 18px;
					padding-bottom: 4px;
					@include rtl-text-align-left();
				}
				.group-price{
					.product-group-price{
						@include rtl-text-align-left();
						.product-price-and-shipping{
							.price{
								font-size: 3rem;
								font-weight: 900;
								color: $theme-color-primary;
							}
							.regular-price{
								font-size: 15px;
								font-weight: 400;
								color: #999999;
								@include rtl-margin-lr(30px, 0);
							}
						}
					}
				}
				.group-buttons{
					margin-top: 54px;
					>a{
						width: 60px!important;
						height: 60px!important;
					}
					.add-to-cart{
						@include rtl-margin-lr(0,10px);
						height: 60px!important;
						min-width: 270px;
					}
				}
			}
		}
		.owl-dots{
			top: auto;
			bottom: -5px;
			@include rtl-left(50%);
			@include translateX(-50%);
			.owl-dot{
				span{
					@include square(15px);
					margin: 0 5px;
					background: $white;
				}
				&:hover, &.active{
					span{
						background: $theme-color-primary;
					}
				}
			}
		}
	}
	@media (max-width: $screen-md-max) and (min-width: $screen-md){
		padding-top: 90px;
		padding-bottom: 90px;
	}
	@media (max-width: $screen-sm-max) and (min-width: $screen-sm){
		padding-top: 70px;
		padding-bottom: 70px;
		.block_content .product-miniature .product-description .group-buttons .add-to-cart{
			min-width: 165px;
		}
	}
	@media (max-width: $screen-xs-max){
		padding-top: 70px;
		padding-bottom: 70px;
		.block_content{
			.product-miniature{
				@include flex-direction(column);
				.thumbnail-container{
					.product-thumbnail{
						@include rtl-margin-lr(34px, 0px);
						&:before{
							@include rtl-left(-50px);
						}
					}
				}
				.product-description{
					margin: 0;
					.group-buttons{
						.add-to-cart{
							min-width: 220px!important;
						}
						.quick-view{
							@include rtl-margin-lr(5px, 5px);
						}
					}
				}
			}
		}
	}
	@media (max-width: 425px){
		.block_content{
			.product-miniature{
				.product-description{
					.group-buttons{
						>a{
							width: 40px!important;
							height: 40px!important;
						}
						.add-to-cart{
							min-width: 170px!important;
							height: 40px!important;
						}
					}
				}
			}
		}
	}
}
//Slider Image
.nov-slider-image{
	padding: 0;
	&:focus{
		outline: none;
	}
	.slick-arrow{
		position: absolute;
		font-family: $font-icon-tello;
		top: 50%;
		@include translateY(-50%);
		cursor: pointer;
		width: 30px;
		height: 50px;
		overflow: hidden;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		z-index: 102;
		background-color: $white;
		border: none;
		@include transition(all 0.35s  ease);
		font-size: 0;
		&:hover{
			background-color: $theme-color-default;
			&:before{
				color: $white;
			}
		}
		&:focus{
			outline: none;
		}
		&.slick-prev {
			left: 0px;
			@include rtl-border-radius-topright(14px);
			@include rtl-border-radius-bottomright(14px);
			&:before{
				content: "\f007";
				font-size: 1.4rem;
			}
		}
		&.slick-next {
			right: 0px;
			@include rtl-border-radius-topleft(14px);
			@include rtl-border-radius-bottomleft(14px);
			&:before{
				content: "\f006";
				font-size: 1.4rem;
			}
		}
	}
	.slick-images-slider{
		.slick-list{
			overflow: inherit;
			.slick-slide{
				@include rtl-padding-lr(15px, 15px);
			}
		}
		.slick-next{
			@include rtl-right(15px);
		}
		.slick-prev{
			@include rtl-left(15px);
		}
	}
}

// Image
.nov-image {
	.block{
		position: relative;
		.content-banner{
			position: absolute;
			z-index: 2;
			.button-image{
				font-size: 1.4rem;
				font-weight: 700;
				color: $theme-color-primary;
				padding: 2.2rem 5.2rem;
				background: $white;
				@include transition(all 0.35s ease);
				text-transform: uppercase;
				white-space: nowrap;
				@media (max-width: $screen-sm-max){
					padding: 1.2rem 2rem !important;
					min-width: 120px!important;
				}
			}
			&:hover{
				.button-image{
					background: $theme-color-primary;
					color: $white;
				}
			}
		}
		&.effect_default{
			.content-banner{
				display: none;
			}
		}
		&.effect_1{
			.block_content{
				@include transition(all 0.35s ease);
			}
			.content-banner{
				@include rtl-left(10px);
				bottom: 20px;
				.button-image{
					display: inline-block;
					font-size: 14px;
					font-weight: 400;
					color: #444545;
					padding: 16px;
					text-transform: uppercase;
					min-width: 220px;
					@include transition(all 0.35s ease);
					text-align: center;
					&:hover{
						background-color: $theme-color-default;
						color: $white;
						@include box-shadow(0 2px 5px 0 rgba(0,0,0,0.05));
					}
				}
			}
			@media(max-width: $screen-xs-max){
				.content-banner{
					@include rtl-left(50%);
					@include translateX(-50%);
				}
			}
		}
		&.effect_2{
			.block_content{
				@include transition(all 0.35s ease);
			}
			.content-banner{
				@include rtl-right(20px);
				bottom: 20px;
				.button-image{
					display: inline-block;
					font-size: 14px;
					font-weight: 400;
					color: #444545;
					padding: 16px;
					text-transform: uppercase;
					min-width: 200px;
					@include transition(all 0.35s ease);
					text-align: center;
					&:hover{
						background-color: $theme-color-default;
						color: $white;
						@include box-shadow(0 2px 5px 0 rgba(0,0,0,0.05));
					}
				}
			}
		}
		
	}
	.effect {
		overflow: hidden;
		position: relative;
		@include transition(all 0.35s ease);
		text-align: center;
		a{
			display: block;
			overflow: hidden;
			position: relative;
			&:before{
				content: "";
				bottom: 0;
				left: 50%;
				right: 51%;
				top: 0;
				@include opacity(1);
				position: absolute;
				background: rgba(0,0,0,.1) none repeat scroll 0 0;
			}
			&:after{
				content: "";
				bottom: 50%;
				left: 0;
				right: 0;
				top: 50%;
				@include opacity(1);
				position: absolute;
				background: rgba(0,0,0,.1) none repeat scroll 0 0;
			}
		}
		&:hover {
			a{
				&:before{
					left: 0;
					right: 0;
					@include opacity(0);
					@include transition(all 700ms ease-in-out 0s);
				}
				&:after{
					bottom: 0;
					top: 0;
					@include opacity(0);
					@include transition(all 700ms ease-in-out 0s);
				}
			}
		}
	}
}
//Instagram
.nov-instafeed {
	.title_block{
		position: relative;
		padding-bottom: 0;
		&:after{
			display: none;
		}
		>span{
			position: relative;
			display: inline-block;
			padding-bottom: 15px;
			&:before{
				content: "\eba9";
				font-family: $font-icon-tello;
				font-size: 23px;
				color: $theme-color-default;
				position: absolute;
				@include rtl-left(-28px);
				bottom: 19px;
			}
			&:after{
				content: "";
				position: absolute;
				width: 100px;
				height: 2px;
				background: $theme-color-default;
				@include rtl-left(0%);
				bottom: 0;
			}
		}
		
	}
	.boxInstagram{
		.owl-nav{
			[class*='owl-']{
				top: 50%;
				@include translateY(-50%);
				color: $white;
				&:hover{
					color: $black;
				}
			}

			.owl-prev{
				@include rtl-left(0);
			}
			.owl-next{
				@include rtl-right(0);
			}
		}
		.owl-dots{
			bottom: 0px;
			top: auto;
			@include rtl-left(50%);
			@include translateX(-50%);
			line-height: 1;
			.owl-dot{
				span{
					&:before{
						@include size(13px, 13px);
						background: #d8d8d8;
					}
				}
				&.active, &:hover{
					span{
						&:before{
							background: $theme-color-default;
						}
					}
				}
			}
		}	
	}
	.image-thumb{
		position: relative;
		display: block;
		&:before{
			content: "\e9ed";
			font-size: 1.8rem!important;
			font-family: $font-icon-tello;
			color: $white;
			background-color: rgba(255,255,255,0.5);
			position: absolute;
			@include border-radius(50%);
			width: 40px;
			height: 40px;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			line-height: 40px;
			filter: alpha(opacity=0);
			@include opacity(0);
			z-index: 1;
			@include scale(0, 0);
			@include transition(all 0.4s ease);
		}
		&:after{
			content: '';
			position: absolute;
			@include transition(all 0.4s ease);
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0,0,0,0.5);
			@include opacity(0);
		}
		&:hover{
			&:after, &:before{
				@include opacity(1);
			}
			&:before{
				color: $white;
				@include scale(1,1);
			}
		}
	}
	.boxInstagram-grid{
		.block-grid-instagram{
			@include rtl-padding-lr(2.5px, 2.5px);
		}
	}
	.image-thumb {
		width: 100%;
		height: 317.167px;
		background-image: none;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		display: inline-block;
	} 
}