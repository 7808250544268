//Global
.nov-blog{
	padding-top: 120px;
	padding-bottom: 94px;
	@media (max-width: $screen-md-max) and (min-width: $screen-md){
		padding-top: 90px;
		padding-bottom: 64px;
	}
	@media (max-width: $screen-xs-max){
		padding-top: 70px;
		padding-bottom: 44px;
	}
}
.grid_style{
	.post-item-left{
		.description-content{
			position: relative;
			@include rtl-margin-lr(20px, 20px);
			margin-top: -120px;
			padding: 51px 20px 34px 20px;
			@include border-radius(4px);
			background: $white;
			@include box-shadow(4px 4px 0 0 $theme-color-default);
			.post-info{
				position: absolute;
				top: -30px;
				@include rtl-left(20px);
				min-width: 60px;
				@include rtl-padding-lr(10px, 10px);
				height: 60px;
				background: $theme-color-primary;
				@include border-radius(4px);
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				@include flex-direction(column);
				>span{
					display: block;
				}
				.post-date{
					font-size: 2.4rem;
					font-weight: 700;
					color: $white;
					line-height: 1;
				}
				.post-month{
					font-size: 13px;
					font-weight: 500;
					color: $white;
					line-height: 1;
				}
			}
			.post_title{
				padding-bottom: 15px;
				a{
					font-family: $font-family-default;
					font-size: 18px;
					font-weight: 600;
					color: $theme-color-primary;
					padding-bottom: 40px;
					line-height: 26px;
					&:hover{
						color: $theme-color-default;
					}
				}
			}
			.post-desc{
				font-size: 14px;
				color: $theme-color-primary;
				line-height: 22px;
			}
		}
	}
	.post-item-right{
		
		.post-content{
			.post-main{
				margin-bottom: 30px;
				padding-bottom: 20px;
				background: $white;
				@include border-radius(4px);
				@include box-shadow(4px 4px 0 0 $theme-color-default);
				@include flexbox();
				@include align-items(center);
				.post-image{
					max-width: 47.3685%;
					position: relative;
					a{
						display: inline-block;
					}
					.post-info{
						position: absolute;
						top: 10px;
						@include rtl-left(10px);
						min-width: 60px;
						@include rtl-padding-lr(10px, 10px);
						height: 60px;
						background: $theme-color-primary;
						@include border-radius(4px);
						@include flexbox();
						@include justify-content(center);
						@include align-items(center);
						@include flex-direction(column);
						>span{
							display: block;
						}
						.post-date{
							font-size: 2.4rem;
							font-weight: 700;
							color: $white;
							line-height: 1;
						}
						.post-month{
							font-size: 13px;
							font-weight: 500;
							color: $white;
							line-height: 1;
						}
					}
				}
				.description-content{
					@include rtl-padding-lr(20px,30px);
					.post_title{
						padding-bottom: 15px;
						a{
							font-family: $font-family-default;
							font-size: 18px;
							font-weight: 600;
							color: $theme-color-primary;
							padding-bottom: 40px;
							line-height: 26px;
							&:hover{
								color: $theme-color-default;
							}
						}
					}
					.post-desc{
						font-size: 14px;
						color: $theme-color-primary;
						line-height: 22px;
					}
				}
			}
		}
	}
	@media (max-width: $screen-md-max) and (min-width: $screen-md){
		.post-item-left{
			.post-content{
				.description-content{
					margin-top: -14px;
				}
			}
		}
	}
	@media (max-width: $screen-sm-max) and (min-width: $screen-sm){
		.post-item-right{
			padding-top: 60px;
		}
	}
	@media (max-width: $screen-xs-max){
		.post-item-left{
			.post-content{
				.description-content{
					margin-top: 0;
				}
			}
		}
		.post-item-right{
			padding-top: 60px;
		}
	}
	@media (max-width: 525px){
		.post-item-right{
			.post-main{
				@include flex-direction(column);
				.post-image{
					max-width: 100%!important;
				}
				.description-content{
					padding: 25px 0 0 0;
				}
			}
		}
	}
}
.list_style{
	margin: 0 -10px -10px -10px;
	.novblog-box-content {
		padding: 0 10px 10px 10px;
		overflow: hidden;
		.owl-stage-outer{
			overflow: initial;
		}
		.post-image{
			>a{
				display: block;
				overflow: hidden;
			}
			img{
				@include transition(all 0.35s ease-in-out);
			}
			&:hover{
				img{
					@include scale(1.05,1.05);
				}
			}
		}
		.post-main{
			@include border-radius(5px);
			@include transition(all 0.35s ease);
			.post-image{
				position: relative;
				margin-bottom: 63px;
				.post-info{
					position: absolute;
					bottom: -30px;
					@include rtl-left(10px);
					min-width: 60px;
					@include rtl-padding-lr(10px, 10px);
					height: 60px;
					background: $theme-color-primary;
					@include border-radius(4px);
					@include flexbox();
					@include justify-content(center);
					@include align-items(center);
					@include flex-direction(column);
					>span{
						display: block;
					}
					.post-date{
						font-size: 2.4rem;
						font-weight: 700;
						color: $white;
						line-height: 1;
					}
					.post-month{
						font-size: 13px;
						font-weight: 500;
						color: $white;
						line-height: 1;
					}
				}
			}
			.description-content{
				padding: 0 10px 40px 10px;
				.post_title{
					padding-bottom: 15px;
					>a{
						font-size: 1.8rem;
						font-weight: 600;
						color: $theme-color-primary;
						&:hover{
							color: $theme-color-default;
						}
					}
				}
				.post-desc{
					font-size: 14px;
					font-weight: 400;
					color: $theme-color-primary;
					line-height: 22px;
				}
			}
			&:hover{
				@include box-shadow(4px 4px 0 0 $theme-color-default);
			}
		}
	}
}


// block-recentpost
.block-recentpost {
	font-size: 13px;
	font-family: $font-family-primary;
	padding-bottom: 24px!important;
	.post-item {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #e0e0e0;
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
		.post-title {
			display: block;
			color: $text-color-title;
			font-weight: 700;
			margin-bottom: 12px;
			font-size: 16px;
			font-family: $font-family-default;
			text-transform: capitalize;
			text-decoration: none;
			&:hover{
				color: $theme-color-default;
			}
		}
		.post-description {
			padding-top: 3px;
			padding-bottom: 10px;
			line-height: 18px;
			font-size: 12px;
			color: $text-color;
		}
		.post-info {
			font-size: 12px;
			color: $text-color-custom;
			margin-bottom: 9px;
			text-transform: uppercase;
			.comment{
				@include rtl-padding-right(11px);
				display: inline-block;
				margin-bottom: 5px;
				&:before{
					content:"";
				}
				a{
					color: $text-color-custom;
					&:hover{
						color: $theme-color-default;
					}
				}
				i{
					display: inline-block;
					@include rtl-margin-right(5px);
				}
			}
			.datetime{
				@include rtl-padding-left(10px);
				margin-bottom: 5px;
				i{
					display: inline-block;
					@include rtl-margin-right(5px);
				}
			}
		}
		.readmore a {
			font-weight: 500;
			color: $theme-color-default;
			text-transform: uppercase;
			@include transition(all 0.35s ease);
			font-size: 12px;
			text-decoration: none;
			&:hover{
				color: $theme-color-primary;
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	#module-smartblog-category .blogwapper {
		&.has-sidebar-left {
			@include rtl-padding-left(8.045%);
		}
		&.has-sidebar-right {
			@include rtl-padding-right(8.045%);
		}
	}
}