.block-product{
	.block_content{
		margin: -20px;
		.product_list{
			padding: 20px;
			.owl-stage-outer{
				overflow: initial;
			}
		}
	}
}
.qty {
    display: inline-block;
    vertical-align: top;
    .input-group-btn-vertical {
	    float: none;
	    z-index: 1;
	    @include flexbox();
	    @include align-items(center);
	    @include flex-direction(row-reverse);
	    height: 40px;
	    .btn {
	      padding: 0;
	      @include square(17px);
	      line-height: 17px;
	      background: transparent;
	      i {
	        color: $theme-color-primary;
	        font-size: 24px;
	        font-family: $font-family-default;
	        display: block;
	        font-style: inherit;
	        @include rtl-margin-lr(0,0);
	        &.touchspin-up {
	          &:before{
	            content: '+';
	          }
	        }
	        &.touchspin-down {
	          &:before{
	            content: '-';
	          }
	        }
	      }
	      &.bootstrap-touchspin-down {
	        
	      }
	    }
	}
}
#quantity_wanted {
	color: $theme-color-primary;
    background-color: white;
    height: 40px;
    width: 130px;
    font-size: 13px;
    font-weight: 700;
    border: 1px solid #f2f2f2;
    @include border-radius(3px);
    z-index: 1;
    text-align: center;
}
//Countdown
.countdownfree {
	font-family: $font-family-default;
	@include flexbox();
	@include align-items(flex-start);
	@include justify-content(flex-start);
	.item-time {
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		@include flex-direction(column);
		position: relative;
		font-family: $font-family-default;
		width: 70px;
		height: 70px;
		@include transition(all 0.35s ease);
		background: $white;
		@include box-shadow(4px 4px 0 0 $theme-color-default);
		@include rtl-margin-lr(0, 20px);
		@include border-radius(4px);
	}
	.name-time {
		display: block;
		font-size: 11px;
		color: #999999;
		line-height: 1;
		font-weight: 700;
		margin-top: 3px;
		margin-bottom: 3px;
		text-transform: capitalize;
	}
	.data-time {
		color: $theme-color-primary;
		font-size: 24px;
		font-weight: 700;
		line-height: 1;
	}

}

.product-title {
	font-size: 14px;
	a {
		font-family: $font-family-primary;
		font-size: 14px;
		color: $product-title;
		font-weight: 500;
		line-height: 1.75;
		&:hover {
			color: $theme-color-default;
			text-decoration: none;
		}
	}
}
.product-flags {
	position: absolute;
	top: 9px;
	color: $theme-color-primary;
	text-align: center;
	line-height: 1;
	font-size: 11px;
	font-weight: 400;
	&.new {
		@include rtl-left(10px);
	}
	&.on-sale {
		@include rtl-left(10px);
	}
	&.discount {
		@include rtl-left(10px);
	}
	@media (max-width: $screen-xs-max){
		display: none;
	}
}

.product-info {
	position: absolute;
	@include opacity(0);
	width: 100%;
	top: 40%;
	left: 0;
	padding: 20px;
	@include translateY(-50%);
	z-index: 1;
	@include transition(all 300ms ease-in-out);
	.product-desc {
		display: block;
		color: #fff;
		font-size: 1.2rem;
	}
	.product-title {
		a {
			color: #fff;
			font-size: 1.4rem;
			&:hover {
				color: $theme-color-default;
			}
		}
	}
	.product-comments{
		> span {
			display: none;
		}
		.star_content {
			display: inline-block;
		}

	}
	@media (max-width: 767px) {
		display: none;
	}
}

.product-comments {
	.star_content {
		@include flexbox();
		@include justify-content(start);
		@include align-items(center);
	    overflow: hidden;
	}
	span {
		display: none;
	}
}

//Product miniature
.product-miniature {
	position: relative;
	margin-top: 30px;
	@include border-radius(3px);
	border: 1px solid #f2f2f2;
	@include transition(all 0.5s ease);
	&.first_item{
		margin-top: 0;
	}
	//Thumbnail
	.thumbnail-container {
		position: relative;
		overflow: hidden;
		text-align: center;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		border: 1px solid transparent;
		//two image
		.two-image{
			display: block;
			position: relative;
			.image-cover {
				@include opacity(1);
				@include transition(all 400ms ease-in-out 0s);
			}
			.image-secondary {
				position: absolute;
				overflow: hidden;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				@include opacity(0);
				@include transition(all 400ms ease-in-out 0s);
				margin: 0 auto;
			}
		}
		.one-image{
			display: block;
			position: relative;
			.image-cover {
				@include opacity(1);
				@include transition(all 400ms ease-in-out 0s);
			}
		}
	}

	.info-stock {
		display: none;
	}
	.group-button-thumb{
		position: absolute;
		top: 10px;
		@include rtl-right(-45px);
		@include flexbox();
		@include flex-direction(column);
		@include justify-content(center);
		@include align-items(center);
		@include transition(all 0.35s ease-in-out);
		z-index: 2;
		.addToWishlist {
			.nov-icon-wishlist{
				display: inline-block;
				@include size(23px, 21px);
				background-image: url('../img/icon/icon-group-header.png');
				background-position: -96px -24px;
				background-repeat: no-repeat;
			}
			&.checked{
				pointer-events: none;
				cursor: not-allowed;
			}
			&:hover{
				background: $theme-color-default;
			}
		}
		.quick-view{
			.icon-quick-view{
				display: inline-block;
				@include square(21px);
				background-image: url('../img/icon/icon-group-header.png');
				background-position: -125px -24px;
				background-repeat: no-repeat;
			}
			&:hover{
				background: $theme-color-default;
			}
		}
		a{
			font-size: 0;
			width: 41px;
			height: 41px;
			text-align: center;
			background: $theme-color-primary;
			@include border-radius(4px);
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);
			margin-bottom: 10px;
			@include transition(all 0.35s ease-in-out);
			&:last-child{
				margin-bottom: 0;
			}
			i{
				font-size: 16px;
				color: $white;
			}
			&:hover{
				background: $theme-color-default;
			}
		}
	}
	.group-buttons{
		position: absolute;
		top: 100%;
		@include rtl-left(50%);
		@include translateX(-50%);
		@include flexbox();
		@include justify-content(center);
		@include align-items(center);
		@include transition(all 0.35s ease);
		.addToWishlist {
			@include opacity(0);
			@include transition(all 500ms ease-in-out 0s);
			position: relative;
			background: $theme-color-primary;
			.nov-icon-wishlist{
				display: inline-block;
				@include size(23px, 21px);
				background-image: url('../img/icon/icon-group-header.png');
				background-position: -96px -24px;
				background-repeat: no-repeat;
			}
			&.checked{
				pointer-events: none;
				cursor: not-allowed;
			}
			&:hover{
				background: $theme-color-default;
				.nov-icon-wishlist{
					background-position: -96px -0px;
				}
			}
		}
		.quick-view{
			@include opacity(0);
			@include transition(all 350ms ease-in-out 0s);
			background: $theme-color-primary;
			.nov-icon-quick-view{
				display: inline-block;
				@include square(21px);
				background-image: url('../img/icon/icon-group-header.png');
				background-position: -125px -24px;
				background-repeat: no-repeat;
			}
			&:hover{
				background: $theme-color-default;
				.nov-icon-quick-view{
					background-position: -125px -0px;
				}
			}
		}
		a{
			font-size: 15px;
			font-weight: 500;
			width: 41px;
			height: 41px;
			min-width: 41px;
			text-align: center;
			@include border-radius(4px);
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);
			@include rtl-margin-lr(10px, 10px);
			&:hover{
				background: $theme-color-default;
			}
		}
		form{
			@include opacity(0);
		}
		.add-to-cart {
			position: relative;
			@include flexbox();
			@include align-items(center);
			text-decoration: none;
			width: auto;
			min-width: 150px;
			background-color: $theme-color-default;
			@include border-radius(4px);
			@include transition(all 0.35s ease);
			span{
				&:not(.loading){
					font-size: 15px;
					font-weight: 500;
					color: $white;
					display: inline-block;
					@include rtl-padding-lr(10px, 5px);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.loading{
				position: absolute;
				top: 50%;
				@include rtl-left(50%);
				@include translate(-50%,-50%);
				color: #fff;	
				font-size: 1.5rem;
				display: none;
				line-height: 1;
				i{
					margin: 0;
				}
			}
			&.cart-loading{
				background: $theme-color-default;
				.loading{
					display: inline-block;
				}
				.novicon-cart, span:not(.loading){
					@include opacity(0);
				}
				
			}
			i {
				&.novicon-cart {
					display: inline-block;
					background-image: url(../img/icon/icon-group-header.png);
					background-repeat: no-repeat;
					width: 23px;
					height: 21px;
					background-position: 0px -24px;
				}
			}
			&:hover{
				background-color: darken($theme-color-default,20%);
				border-color: darken($theme-color-default,20%);
				span{
					&:not(.loading){
						font-size: 15px;
						font-weight: 500;
						display: inline-block;
					}
				}
				
			}
			&:focus {
				outline: none;
			}
			&:disabled {
				cursor: not-allowed;
			}
		}
	}
	
	.product-group-price{
		line-height: 1;
		padding-top: 9px;
		.product-price-and-shipping{
			.price{
				font-size: 16px;
				font-weight: 900;
				color: $theme-color-primary;
			}
			.regular-price{
				font-size: 11px;
				font-weight: 400;
				color: #999999;
				@include rtl-margin-lr(20px, 0px);
				text-decoration: line-through;
			}
		}
	}
	.product-description{
		padding: 24px 0 26px 0;
		margin: 0 19px;
		border-top: 1px dashed #f2f2f2;
		@include rtl-text-align-left();
		position: relative;
		.product-title{
			padding-top: 14px;
			padding-bottom: 13px;
			@include rtl-text-align-left();
			>a{
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				@include rtl-text-align-left();
			}
		}
	}
	&.item-default {
		text-align: center;
		position: relative;
		.thumbnail-container {
			padding: 10px;
			margin-bottom: 0;
		}
		.product-description {
			padding: 0 10px;
		}
		.product-buttons {
			position: absolute;
			bottom: -37px;
			margin: -1px;
			width: 100%;
			width: calc(100% + 3px);
			@include opacity(0);
			visibility: hidden;
			@include transition(all 0.25s ease);
		}
		&:hover {
			.product-buttons {
				@include opacity(1);
				visibility: visible;
				bottom: -1px;
			}
			.group-action {
				@include rtl-right(0);
				@include opacity(1);
			}
			.product-group-price {
				@include opacity(0);
			}
		}
	}

	&.item-one {
		position: relative;
		&:before{
			content: "";
			position: absolute;
			@include opacity(0);
			border: 1px solid #f2f2f2;
			@include box-shadow(0px 0px 10px 0px rgba(242, 242, 242, 1));
			@include border-radius(3px);
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			@include transition(all 0.35s ease);
			background: $white;
		}
		.thumbnail-container {
		    position: relative;

		}
		.product-groups{
			.group-buttons{
				width: 100%;
				top: calc(100% + 30px);
				form{
					@include transition(all 0.35s ease);
					.add-to-cart{
						position: absolute;
						width: calc(100% - 38px);
						@include rtl-left(50%);
						@include translateX(-50%);
						margin: 0;
					}
				}
			}
		}
		&:hover{
			z-index: 2;
			border-color: transparent;
			.product-groups{
				.group-buttons{
					top: 100%;
				}
				
			}
			&:before{
				bottom: -70px;
				@include opacity(1);
			}
		}
	}

	&:hover {		
		.group-buttons{
			form{
				@include opacity(1);
			}
		}
		.thumbnail-container {
			.two-image{
				.image-cover{
					@include opacity(0);
				}
				.image-secondary {
					@include opacity(1);
				}
			}
			.one-image{
				.image-cover{
					@include opacity(1);
				}
			}
			.group-button-thumb{
				@include rtl-right(10px);
			}
		}

	}
}

// Item product in list
.product_list {
	&.grid {
		.owl-item {
			&:hover {
				position: relative;
			}
		}
	}
	.item {
		&.item-animate {
			-webkit-animation: fadeInUp 1s ease both 0s;
			-moz-animation: fadeInUp 1s ease both 0s;
			-o-animation: fadeInUp 1s ease both 0s;
			animation: fadeInUp 1s ease both 0s;
	    }
	}
}

#js-product-list {
	.product_list {
		&.grid {
			.product-miniature {

			}
		}
		&.list {	
			.item {
				margin-bottom: 3rem;
				-webkit-flex: 0 0 100%;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				width: 100%;
			}
			.product-miniature {
				margin-top: 0;
				@include justify-content(center);
				@include align-items(center);
				&:before{
					display: none;	
				}
				.thumbnail-container {
					position: relative;
					margin: 0;
					@include rtl-border-right(1px dashed #f2f2f2);
					>a {
						display: block;
					}
				}

				.product-description {
					@include rtl-text-align-left();
					padding-bottom: 0;
					@include flexbox();
					@include flex-direction(column);
					padding: 0;
					margin: 0;
					padding-bottom: 32px;
					border-bottom: 1px dashed #f2f2f2;
					border-top: none;
					.product-title {
						font-size: 14px;
						font-weight: 500;
						@include order(-1);
						padding-top: 0;
						padding-bottom: 22px;
						a{
							font-size: 14px;
							font-weight: 500;
							color: $product-title;
							text-transform: capitalize;
							&:hover{
								color: $theme-color-default;
							}
						}
					}
					.product-desc{
						font-size: 1.5rem;
						color: $theme-color-primary;
						line-height: 24px;
						padding-top: 20px;
						padding-bottom: 23px;
						border-bottom: 1px solid #f2f2f2;
						margin-bottom: 20px;
					}
					.product-comments{
						.star_content{
							@include  justify-content(flex-start !important);
						}
					}
					.product-group-price{
						padding-top: 25px;
						.product-price-and-shipping{
							.regular-price{
								font-size: 1.1rem;
								font-weight: 400;
								color: #999999;
								@include rtl-margin-lr(20px, 0);
							}
							.price{
								font-size: 2.4rem;
								font-weight: 900;
								color: $theme-color-primary;
							}
						}
					}
					
				}
				.group-buttons{
					@include justify-content(flex-start !important);
					position: relative;
					@include rtl-left(0);
					@include translateX(0);
					margin-top: 35px;
					top: 0;
					>a, form{
						position: relative;
						left: initial;
						right: initial;
						top: initial;
						@include opacity(1);
						@include border-radius(3px);
					}
					.quick-view{
						@include rtl-margin-lr(10px, 0px);
						span{
							font-size: 0;
						}
						.icon-quick-view{
							display: inline-block;
							@include square(21px);
							background-image: url('../img/icon/icon-group-header.png');
							background-position: -125px -24px;
							background-repeat: no-repeat;
						}
					}
					form{
						.add-to-cart{
							position: relative;
							@include border-radius(3px!important);
							min-width: 230px;
							>span{
								font-size: 15px;
								font-weight: 500;
								@include rtl-padding-lr(10px, 0);
								color: $white;
							}
							&:hover{
								>span{
									color: $theme-color-primary;
								}
							}
						}
					}
				}
				.product-flags{
					display: none;
					top: 10px;
					@include rtl-right(10px);
					.discount{
						@include rtl-right(10px);
					}
				}
				.product-desc-right {
					text-align: left !important;

				}
				
				&:hover {
					border: 1px solid #f2f2f2;
					@include box-shadow(0px 0px 10px 0px rgba(242, 242, 242, 1));
					.product-groups{
						.product-description{
							.product-group-price{
								@include opacity(1);
								@include translateY(0);
							}
						}
					}
				}
				
			}
			@media (max-width: $screen-sm-max){
				.product-miniature{
					.product-groups{
						.group-buttons{
							margin-bottom: 30px;
							.form-add-cart{
								.add-to-cart{
									min-width: 41px;
									span{
										&:not(.loading){
											display: none;
										}
									}
								}
							}
						}
					}
				}
			}
			@media (max-width: $screen-xs-max){
				.product-miniature{
					.thumbnail-container{
						@include rtl-border-right(none);
						border-bottom: 1px dashed #f2f2f2;
						margin-bottom: 25px;
					}
				}
			}
		}
	}
}