/* Block tags */
div.tags_block {
	position: relative;
	.title_tags_block{
		font-size: 2rem;
		font-weight: 500;
		color: $theme-color-primary;
		padding-bottom: 16px;
		line-height: 0.8;
		border-bottom: 4px solid $theme-color-default;
		margin-bottom: 23px;
	}
	.block_content {
		font-size: 1.2rem;
		overflow: hidden;
		a{
			display: inline-block;
			font-size: 1.1rem;
			font-weight: 400;
			padding: 7px 11px;
			color: #909090;
			border: 1px solid #f5f5f5;
			background: transparent;
			@include rtl-float-left();
			@include rtl-margin-right(7px);
			margin-top: 7px;
			position: relative;
			@include transition(all 0.35s ease);
			@include border-radius(2px);
			&:hover {
				border-color: $theme-color-default;
				background: $theme-color-default;
				color: $white;
			}
		}
	}
}